import { Carousel } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { replacePath } from '../../../../utils';
import { PriceLine } from '../../../components';
import commonStyles from '../../../homePage/common.less';
import { SwiperButton } from '../../../homePage/dynamicInfo';
import styles from './index.less';

export const StallNewStyle = observer((props) => {
  const {
    stallNewStyleList,
    setStallNewStyleListCarousel,
    mainNext,
    mainPre,
  } = props;
  return (
    <div className={styles.stallNewStyleWrap}>
      <header className={styles.header}>
        <span className={styles.title}>
          档口最新款
        </span>
      </header>
      <div className={`${styles.showRoomWrap} ${commonStyles.commonCarousel}`}>
        <Carousel
          autoplay
          dots={false}
          ref={setStallNewStyleListCarousel}
        >
          {
            stallNewStyleList?.map((item, index) => {
              return (
                <div
                  className={styles.carouselImgWrap}
                  key={item[index]}
                >
                  {
                    item?.map(({
                      price,
                      mainPicUrl,
                      goodsId,
                      discountPrice,
                      activityPrice,
                    }, indx) => {
                      return (
                        <div
                          className={styles.newStyleGoodsWrap}
                          key={goodsId}
                          onClick={() => {
                            window.open(replacePath(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}`));
                          }}
                        >
                          <div className={styles.imgWrap}>
                            <img
                              alt=""
                              src={mainPicUrl}
                            />
                          </div>
                          <div className={styles.priceWrap}>
                            <PriceLine
                              activePrice={activityPrice}
                              costPrice={price}
                              discountPrice={discountPrice}
                            />
                          </div>
                        </div>
                      );
                    })
                  }
                </div>

              );
            })
          }
        </Carousel>
        <SwiperButton
          carouselRef="stallNewStyleListRef"
          mainNext={mainNext}
          mainPre={mainPre}
          nextStyle={{ top: 125 }}
          preStyle={{ top: 125 }}
        />
      </div>
    </div>
  );
});
