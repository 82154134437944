import { Button, Col, Modal, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import zipLog from '../../../../assets/images/zipLogo.png';
import styles from './index.less';
import type { IDownloadPicPackageStore } from './store';

interface IProps {
  store: IDownloadPicPackageStore;
}

const DownloadModal = observer((props: IProps): JSX.Element => {
  const {
    visible,
    onModalClose,
    groupVideoAndPics,
    loading,
    frontOrBack,
    downLoadPackage,
    loadingGroupIds,
  } = props.store;
  return (
    <Modal
      destroyOnClose
      footer={null}
      maskClosable={false}
      onCancel={onModalClose}
      open={visible}
      title="一键下载"
      width={631}
    >
      <Spin spinning={loading}>
        <div className={styles.container}>
          <div className={styles.tip}>
            可下载多套图片，包含主图，颜色图，详情图
          </div>
          <div className={`${styles.picsContainer} ${frontOrBack === 'front' ? styles.front : ''}`}>
            <Row gutter={[
              28,
              28,
            ]}
            >
              {
                groupVideoAndPics?.map((el) => {
                  return (
                    <Col
                      key={el.groupId}
                      span={6}
                    >
                      <VideoOrPic
                        groupId={el.groupId}
                        groupName={el.groupName}
                        loadingGroupIds={loadingGroupIds}
                        onClickDownload={downLoadPackage}
                        showUrl={el.type === 'video' ? el.videoUrl : el.smallPicUrl}
                        store={props.store}
                        type={el.type}
                      />
                    </Col>
                  );
                })
              }

            </Row>
          </div>
        </div>
      </Spin>
    </Modal>
  );
});

const VideoOrPic: React.FC<{
  type: 'picture' | 'video';
  showUrl: string;
  groupName: string;
  groupId: number;
  loadingGroupIds: number[];
  onClickDownload: (value: number, type: 'video' | 'picture', groupName: string) => void;
  store: IDownloadPicPackageStore;
}> = observer((props) => {
  const {
    type,
    showUrl,
    groupId,
    groupName,
    onClickDownload,
    store,
    loadingGroupIds,
  } = props;
  return (
    <div
      className={styles.picsItem}
    >
      <div
        className={styles.imgBorder}
      >

        {type === 'video' && (
          <>
            <video
              height={88}
              preload="metadata"
              src={`${showUrl}#t=0.1`}
              width={88}
            />
            <span className={styles.mainTips}>
              主图视频
            </span>
          </>
        )}
        {type === 'picture' && (
          <>
            <img
              className={styles.img}
              src={showUrl}
            />
            <img
              className={styles.zipLog}
              height={30}
              src={zipLog}
              width={25}
            />
          </>
        )}
      </div>
      <div className={styles.imgsCate}>
        {groupName}
      </div>
      <div className={styles.downloadBtn}>
        <Button
          disabled={loadingGroupIds.includes(groupId)}
          onClick={onClickDownload.bind(store, groupId, type, groupName)}
          size="small"
          type="primary"
        >
          {loadingGroupIds.includes(groupId) ? '打包中...' : '下载'}
        </Button>
      </div>
    </div>
  );
});

export default DownloadModal;
