import React from 'react';
import styles from './index.less';

const Tag = ({
  title,
  color,
  backgroundColor,
}: { title: string; color: string; backgroundColor: string; }) => {
  return (
    <div
      className={styles.tag}
      style={{
        color,
        backgroundColor,
      }}
    >
      <span>
        {title}
      </span>
    </div>
  );
};
export default Tag;
