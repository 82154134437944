import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import styles from './index.less';

const PicSeries: React.FC<{
  goodsPicGroup: Array<{
    picGroupId: number;
    picGroupName: string;
    picUrl: string;
  }>;
  currentPicGroupId: number;
  onChangePicGroup: (picGroupId: number) => void;
}> = observer((props) => {
  const { goodsPicGroup = [], onChangePicGroup, currentPicGroupId } = props;

  const [
    picSeriesOpen,
    setPicSeriesOpen,
  ] = useState(false);

  const onTogglePicSeries = (): void => {
    setPicSeriesOpen(!picSeriesOpen);
  };

  return (
    <div
      className={styles.backTop}
      style={{ display: `${goodsPicGroup?.length > 1 ? 'block' : 'none'}` }}
    >
      <div className={`${styles.backTopContent} ${picSeriesOpen ? `${styles.downAniamtion}` : `${styles.upAniamtion}`}`}>
        {goodsPicGroup?.map((item) => (
          <div
            className={styles.imgWrapper}
            key={item.picGroupId}
            onClick={() => onChangePicGroup(item.picGroupId)}
          >
            <img
              className={`${styles.topImg} ${currentPicGroupId === item.picGroupId ? `${styles.selected}` : ''}`}
              src={item.picUrl}
            />
          </div>
        ))}
      </div>
      <div
        className={styles.footer}
        onClick={onTogglePicSeries}
      >
        <i className={picSeriesOpen ? `icon-arrow_pulldown ${styles.arrowIconDown}` : `icon-arrow_pulldown ${styles.arrowIconUp}`}/>
      </div>
    </div>
  );
});
 
export { PicSeries };
