import { toFixed } from 'egenie-common';
import { observer } from 'mobx-react';
import type { ReactElement } from 'react';
import React from 'react';
import { getPrice } from '../../../../utils';
import styles from './index.less';

interface IPriceProps {
  discountPrice: string;
  price: number;
  isShowText: boolean;
  activityPrice?: number;

  maxDiscountPrice?: number;
  maxWholePrice?: number;
  maxActivityPrice?: number;
}

export const Price = observer(({
  discountPrice,
  price,
  isShowText,
  activityPrice,
  maxDiscountPrice,
  maxWholePrice,
  maxActivityPrice,
}: IPriceProps): ReactElement => {
  const _price = getPrice(price, discountPrice, activityPrice);
  const maxPrice = getPrice(maxWholePrice, maxDiscountPrice, maxActivityPrice);
  const max = toFixed(_price, 2)
    .toString()
    .split('.')[0];
  const min = toFixed(_price, 2)
    .toString()
    .split('.')[1];

  // 价格区间最大值
  let sectionMax = 0;
  let sectionMin = 0;
  if (maxPrice !== _price) {
    sectionMax = Number(toFixed(maxPrice, 2)
      .toString()
      .split('.')[0]);
    sectionMin = Number(toFixed(maxPrice, 2)
      .toString()
      .split('.')[1]);
  }
  return (
    <div className={`${styles.flex} ${styles.faifEnd} `}>
      {
        !discountPrice && !activityPrice && isShowText && (
          <span
            className={`${styles.font12} ${styles.mr4} ${styles.c999} ${styles.lh16}`}
          >
            进货价
          </span>
        )
      }
      <span className={`${styles.clRed} ${styles.font500} ${styles.flex} ${styles.faifEnd}`}>
        {
          Boolean(activityPrice) && (
            <div className={`${styles.specialPrice} ${styles.clRed} ${styles.mr4}`}>
              活动
            </div>
          )
        }
        {
          Boolean(discountPrice) && !activityPrice && (
            <div className={`${styles.specialPrice} ${styles.clRed} ${styles.lh16} ${styles.mb1} ${styles.mr4}`}>
              专享
            </div>
          )
        }
        <span className={`${styles.font14} ${styles.lh16} ${styles.font500}`}>
          ¥
        </span>
        <span className={`${styles.font24} ${styles.font500}`}>
          {max}
        </span>
        <span className={`${styles.font16} ${styles.lh16} ${styles.font500}`}>
          .
          {min}
        </span>
        {sectionMax !== 0 && (
          <span className={`${styles.font16} ${styles.lh16} ${styles.font500}`}>
            起
          </span>
        )}
        {
          ((Boolean(discountPrice) && Number(discountPrice) < Number(price)) || (Boolean(activityPrice) && Number(activityPrice) < Number(price))) && (
            <span className={`${styles.font12} ${styles.ml4} ${styles.lh16} ${styles.originPrice} ${styles.clSecondary}`}>
              {sectionMax !== 0
                ? `¥${toFixed(price, 2)}起`
                : `¥${toFixed(price, 2)}`}
            </span>
          )
        }

      </span>
    </div>
  );
});
