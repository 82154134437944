/**
 * 首页：买手精选和展厅，可跳转至活动列表页和展厅详情页
 */
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import commonStyles from '../common.less';
import type { IBaoStyle, setRef, swiperFunc } from '../interface';
import type { Store } from '../store';
import styles from './index.less';
import type DynamicInfoStore from './store';

@observer
export default class DynamicInfo extends React.Component<{ store: Store; dynamicInfoStore: DynamicInfoStore; }> {
  constructor(props) {
    super(props);
  }

  render(): JSX.Element {
    const { mainPre, mainNext, setNewCarousel, setShowRoomCarousel, gotoPage } = this.props.store;
    const { activeLists, countDownTime, activeListsLength, baoStyleList, advertList, jumpStatistics } = this.props.dynamicInfoStore;
    return (
      <div
        className={styles.activeWrap}
      >
        {advertList?.length > 0 ? (
          <div className={`${styles.newCarousel} ${commonStyles.commonCarousel}`}>
            <Carousel
              autoplay
              ref={setNewCarousel}
            >
              {
                advertList.map((item, index) => {
                  const {
                    advertManageId, // 广告ID
                    advertName, // 广告名称
                    advertType, // 广告类型 0-图片 1-视频
                    jumpUrl, // 跳转链接
                    advertUrl, // 广告链接
                  } = item;
                  return (
                    <div
                      className={styles.carouselWrap}
                      key={advertManageId}
                      onClick={() => {
                        if (jumpUrl) {
                          jumpStatistics(advertManageId);
                          gotoPage(jumpUrl);
                        }
                      }}
                    >
                      {advertType === 0 ? (
                        <img
                          alt=""
                          height={372}
                          src={advertUrl}
                          style={{ cursor: 'pointer' }}
                          width={622}
                        />
                      ) : (
                        <video
                          autoPlay
                          controls
                          height={1080}
                          muted
                          width="1920"
                        >
                          <source
                            src={advertUrl}
                            type="video/mp4"
                          />
                        </video>
                      )}
                    </div>
                  );
                })
              }
            </Carousel>
          </div>
        ) : (
          <div className={styles.emptyActiveWrap}>
            <img src={`${PUBLIC_IMAGE_URL}emptyActiveImg.png`}/>
          </div>
        )}
        <BaoStyleListWrap
          baoStyleList={baoStyleList}
          mainNext={mainNext}
          mainPre={mainPre}
          setShowRoomCarousel={setShowRoomCarousel}
        />
      </div>
    );
  }
}

// 全部活动标签
const ShowAllActivityLabel: React.FC<{ handleGoAllActivity: (e) => void; }> = (props) => {
  return (
    <div
      className={styles.showAllActivityLabel}
      onClick={props.handleGoAllActivity}
    >
      全部活动
      <i className="icon-sq"/>
    </div>
  );
};

export const TagList = observer((props) => {
  const { bgColors, tagList, className, tagStyle } = props;
  return (
    <div className={`${styles.tagWrap} ${tagStyle}`}>
      {
        tagList?.slice(0, 4).map((el) => {
          return (
            <div
              className={`${styles.activeDiscount} ${className}`}
              key={el.tagId}
              style={{ color: bgColors[0] || '' }}
            >
              {
                el.tagValue
              }
            </div>
          );
        })
      }
    </div>
  );
});

export const MoreInfo = (props: { title: string;gotoPage: () => void;id?: number; name?: string; }) => {
  const { id, name, title, gotoPage } = props;
  return (
    <div
      className={styles.moreDetail}

      // data-id={JSON.stringify({
      //   name: '款式广场',
      //   businessType: '展厅',
      //   galleryId: id,
      //   galleryName: name,
      //   jump: '/egenie-ts-vogue/exhibitionHall/index',
      // })}
      onClick={gotoPage}
    >
      <span className={styles.moreDetailTxt}>
        {title}
      </span>
    </div>
  );
};

const BaoStyleListWrap = (props: { baoStyleList: IBaoStyle[][]; mainNext: swiperFunc; mainPre: swiperFunc; setShowRoomCarousel: setRef; }) => {
  const { baoStyleList, setShowRoomCarousel, mainNext, mainPre } = props;
  return (
    <div className={`${styles.showRoomWrap} ${commonStyles.commonCarousel}`}>
      <Carousel
        autoplay
        dots={false}
        ref={setShowRoomCarousel}
      >
        {
          baoStyleList?.map((item, index) => {
            return (
              <div
                className={styles.carouselImgWrap}
                key={item[index].id}
              >
                {
                  item?.map(({ id, url }, indx) => {
                    return (
                      <div
                        className={styles.showRoomImgWrap}
                        key={id}
                        onClick={() => {
                          window.open(`/egenie-ts-vogue/goodsDetail/index?goodsId=${id}`);
                        }}
                      >
                        <div className={styles.baoImgWrap}>
                          <img
                            alt=""
                            className={styles.showRoomImg}
                            src={url}
                          />
                        </div>
                        <img
                          alt=""
                          className={styles.baoIcon}
                          src={`${PUBLIC_IMAGE_URL}baoIcon.png`}
                        />
                      </div>
                    );
                  })
                }
              </div>

            );
          })
        }
      </Carousel>
      <SwiperButton
        carouselRef="showRoomCarouselRef"
        mainNext={mainNext}
        mainPre={mainPre}
        nextStyle={{ top: 62 }}
        preStyle={{ top: 62 }}
      />
    </div>
  );
};

export const SwiperButton = observer((props) => {
  const { mainPre, mainNext, nextStyle, preStyle, carouselRef } = props;
  return (
    <>
      <div
        className={`${commonStyles.carouselBtnCommon} ${commonStyles.carouselPreBtn}`}
        onClick={(e) => {
          mainPre(e, carouselRef);
        }}
        style={preStyle}
      >
        <LeftOutlined
          style={{
            fontSize: 12,
            color: '#fff',
          }}
        />
      </div>
      <div
        className={`${commonStyles.carouselBtnCommon} ${commonStyles.carouselNextBtn}`}
        onClick={(e) => {
          mainNext(e, carouselRef);
        }}
        style={nextStyle}
      >
        <RightOutlined
          style={{
            fontSize: 12,
            color: '#fff',
          }}
        />
      </div>

    </>
  );
});
