import { Drawer } from 'antd';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import type NotificationDrawerStore from './store';
import NotificationContent from './content';
import styles from './index.less';
import NotificationCenterStore from './content/store';

const NotificationDrawer = ({
  store,
  systemType,
}: { store: NotificationDrawerStore; systemType: number; }) => {
  const {
    visible,
    onClose,
  } = store;
  const notificationCenterStore = useMemo(() => {
    if (!visible) {
      return null;
    }
    return new NotificationCenterStore(systemType, store.parent);
  }, [
    systemType,
    visible,
  ]);
  if (!store || !notificationCenterStore) {
    return null;
  }
  return (
    <Drawer
      className={styles.drawer}
      height="100%"
      onClose={onClose}
      open={visible}
      title="消息中心"
      width={720}
    >
      <NotificationContent
        callback={onClose}
        store={notificationCenterStore}
      />
    </Drawer>
  );
};
export default observer(NotificationDrawer);
