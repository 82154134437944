import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData, User } from 'egenie-common';
import type { IObj } from 'egenie-utils';
import { request, SlideVerify } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import { api } from '../../utils';
import { APP_ID, REDIRECT_URL } from './constant';
import { phoneReg } from 'egenie-common';

interface LoginErrorInfo{
  data: string;
  status: string;
}

if (!localStorage.browserCode) {
  const agent = navigator.userAgent.toLowerCase();
  const regStrChrome = /chrome\/[\d.]+/gi;
  if (agent.indexOf('chrome') > 0) {
    const chromeCode = agent.match(regStrChrome);
    localStorage.browserCode = chromeCode[0];
  }
}

export class Store {
  public loginRef = React.createRef<FormInstance>();

  public registerRef = React.createRef<FormInstance>();

  @observable public countSeconds = 60;

  @observable public slideVerify = null;

  @observable public showCountDown = false;

  @observable public authimageneed = false;// 是否需要验证码

  @observable public validateImage = 0;// 时间戳

  @observable public tabKey = 'password'; // password: 密码登录 ｜ validCode: 验证码登录

  @observable public isSendCode = false;

  @observable public countDown = 60;

  @observable public timer;

  private modalLogin = false;

  public changeModalLogin = (modalLogin: boolean) => {
    this.modalLogin = Boolean(modalLogin);
  };

  public changeAuthImage = action(() => {
    request({ url: api.getAuthImage }).then((res: Response) => {
      this.validateImage = Date.now();
    });
  });

  @action public changeTab = (key: string): void => {
    this.tabKey = key;
    this.loginRef.current.setFieldsValue({
      username: '',
      password: '',
    });
  };

  // 跳转到别的页面
  private jumpToOtherPage = async(url: string, jumpTo: string) => {
    const userInfo: User = await request({ url: api.user });
    if (userInfo.tenantType?.includes('100001')) {
      const jumpParam = jumpTo && jumpTo !== 'backstage' ? jumpTo : '';
      window.location.replace(`${api.switchToErpHome}&jumpTo=${jumpParam}`); // erp账户跳转到erp首页
    } else {
      window.location.replace(url);
    }
  };

  // 登录
  @action public login = () => {
    const params = window.location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const jumpTo = search.get('jumpTo');
    this.loginRef.current?.validateFields().then((data) => {
      let params: IObj = {
        browserCode: localStorage.browserCode,
        sysType: 'pc_shj',
        tenantModuleSet: '100015',
        imageCode: data.authImage,
        accountType: 2,
        appType: 'pc',
      };
      if (this.tabKey === 'password') {
        params = {
          ...params,
          username: data.username,
          password: data.password,
          shopId: data?.shopId || undefined,
        };
      } else {
        params = {
          ...params,
          mobile: data.username,
          smsCode: data.smsCode,
        };
      }
      request<BaseData<string>>({
        url: this.tabKey === 'password' ? api.login : api.smsLogin,
        method: 'POST',
        data: params,
      }).then((res) => {
        // 弹窗登录 刷新当前页面 正常登录进入首页
        if (this.modalLogin) {
          window.location.reload();
          return;
        }
        switch (jumpTo) {
          case 'backstage': // 商家工作台
            this.jumpToOtherPage('/egenie-erp-home/shjHome', jumpTo);
            break;
          case 'myStyle':// 我的款式
            this.jumpToOtherPage('/egenie-ts-vogue/myStyle/index', jumpTo);
            break;
          case 'myOrder': // 衫海精订单
            this.jumpToOtherPage('/egenie-ts-vogue/myOrder/index', jumpTo);
            break;
          case 'favoriteStyles': // 收藏的款式
            this.jumpToOtherPage('/egenie-ts-vogue/favoriteStyles/index', jumpTo);
            break;
          default: {
            const url = sessionStorage.getItem('VogueLocationHref');
            window.location.replace(url ? url : '/egenie-ts-vogue/homePage/index');
          }
            break;
        }
      }, (err) => {
        this.handleLoginError(err.data);
      });
    });
  };

  private handleLoginError = (res: LoginErrorInfo): void => {
    if (res.status === 'AuthImageNeed') {
      this.changeAuthImage();
      this.authimageneed = true;
    } else if (res.status === 'send_again') {
      this.login();
    } else if (res.status === 'AccountRisk') {
      window.open(res.data);
    }
  };

  // 注册
  public register = async() => {
    const data = await this.registerRef.current?.validateFields();
    delete data.checkPassword;

    // 注册新增appType
    Object.assign(data, { appType: 'pc' });

    // 注册新增客户端类型
    Object.assign(data, { retailRegisterClientType: 1 });
    const res = await request<BaseData>({
      url: api.register,
      method: 'POST',
      data,
    });
    message.success('注册成功！');
    setTimeout(() => {
      window.location.replace('/egenie-ts-vogue/login');
    }, 800);
  };

  /**
   * 切换登录或注册
   */
  public getValidateCode = () => {
    const data = this.registerRef.current?.getFieldsValue();
    if (!data.mobile) {
      message.error('请输入手机号');
      return;
    }

    if (!phoneReg.test(data.mobile)) {
      message.error('请输入正确的手机号');
      return;
    }
    request<BaseData>({
      url: api.sendValidateCode,
      method: 'POST',
      data: { mobile: data.mobile },
    }).then((res) => {
      this.showCountDown = true;
      this.beginCountDown();
    });
  };

  /**
   * 切换登录或注册
   * @param flag boolean true为登录 false为注册
   */
  public toggleLoginFlag = (flag: boolean): void => {
    //
    if (!flag) {
      this.registerRef.current?.resetFields();
      window.top.open('/egenie-ts-vogue/register');
    } else {
      this.loginRef.current?.resetFields();
      window.location.assign('/egenie-ts-vogue/login');
    }
  };

  // 开始倒计时
  @action private beginCountDown = () => {
    if (this.countSeconds > 0) {
      setTimeout(() => {
        this.countSeconds--;
        this.beginCountDown();
      }, 1000);
    } else {
      this.countSeconds = 60;
      this.showCountDown = false;
    }
  };

  @action public gotoFindPassword = () => {
    window.location.assign('/egenie-ts-vogue/findPassword');
  };

  // 验证图形验证码
  @action public showImageCode = (id: string, x: number, y: number): void => {
    const { username } = this.loginRef.current.getFieldsValue();
    if (!username) {
      message.error('请输入手机号');
      return;
    }
    if (!phoneReg.test(username)) {
      message.error('请输入正确的手机号');
      return;
    }
    this.slideVerify?.clear();
    this.slideVerify = new SlideVerify({
      width: 240,
      height: 120,
      x,
      y,
      parentId: id,
      sliderText: '拖动滑块完成拼图，获取手机验证码',
      onSuccess: () => {
        // 发送验证码
        request({
          url: api.sendValidateCode,
          method: 'post',
          data: {
            mobile: username,
            module: '100015',
          },
        }).then(() => {
          this.isSendCode = true;
          this.countDown = 60;
          this.handleCountDown();
          this.slideVerify = null;
        });
      },
    });
  };

  // 倒计时
  @action public handleCountDown = () => {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.countDown -= 1;
      if (this.countDown < 1) {
        clearInterval(this.timer);
        this.isSendCode = false;
      }
    }, 1000);
  };

  @action public loginWechat = () => {
    const state = `${new Date().getTime()}pc_shj`;
    window.localStorage.setItem('wechatState', state);
    const url = `https://open.weixin.qq.com/connect/qrconnect?appid=${APP_ID}&redirect_uri=${REDIRECT_URL}&response_type=code&scope=snsapi_login&state=${state}#wechat_redire`;
    window.open(url);
  };
}
