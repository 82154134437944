import { Descriptions } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

interface Props {
  businessProps: Record<string, string>; // 属性
  htmlUrl: string; // 详情
}

const GoodsAttributes = observer((props: Props): JSX.Element => {
  const { htmlUrl, businessProps } = props;
  return (
    <div className={styles.goodsAttribute}>
      <header className={styles.header}>
        <span className={styles.title}>
          商品详情
        </span>
      </header>
      <Descriptions
        className={styles.descriptions}
      >
        {Object.entries(businessProps).map((el) => {
          return (
            <Descriptions.Item
              className={styles.descriptionItem}
              key={el[0]}
              label={el[0]}
            >
              {el[1]}
            </Descriptions.Item>
          );
        }) }
      </Descriptions>
      <div className={styles.split}/>
      <div
        className={styles.detailPics}
        dangerouslySetInnerHTML={{ __html: htmlUrl }}
      />
    </div>
  );
});

export { GoodsAttributes };
