import { observer } from 'mobx-react';
import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../../utils';
import styles from './index.less';

interface IColorListItem {
  label: string;
  code: string;
  url: string;
  colorSkuInfo: Array<{
    color: string;
    size: string;
    goodsSkuId: number;
    activityPrice: number;
    discountPrice: number;
    price: number;
    number: number;
    stockNum: number;
  }>;
}

interface Props {
  colorList: Array<Partial<IColorListItem>>; // sku list
  picUrl: string; // 大图
  colorCode: string;
  selectedNum: number;
  setPicUrl: (url: string, color: string, colorCode: string) => void;
}

const GoodsColor = observer((props: Partial<Props>): JSX.Element => {
  const { colorList = [], setPicUrl, colorCode } = props;

  const getColorTotalNumber = (colorSkuInfo): number => {
    let total = 0;
    colorSkuInfo?.forEach((v, i) => {
      total += v.number;
    });
    return total;
  };
  return (
    <div className={styles.colorAndSku}>
      <div className={styles.color}>
        <label className={styles.colorLabel}>
          颜色
        </label>
        <div className={styles.skuList}>
          {colorList.map((el) => {
            const totalNumber = getColorTotalNumber(el.colorSkuInfo);
            return (
              <span
                className={el.code === colorCode ? styles.colorItemSeleted : styles.colorItem}
                key={el.code}
                onClick={() => setPicUrl(el.url, el.label, el.code)}
              >
                {el.code === colorCode && (
                  <img
                    className={styles.selectedIcon}
                    src={`${PUBLIC_IMAGE_URL}selectIcon.png`}
                  />
                )}
                {el.url && (
                  <div className={styles.colorImgWrap}>
                    <img
                      className={el.code === colorCode ? styles.colorSmallImgSelected : styles.colorSmallImg}
                      src={el.url}
                    />
                  </div>
                )}
                <span className={styles.colorText}>
                  {el.label}
                </span>
                {Boolean(totalNumber) && (
                  <div className={styles.selectedNum}>
                    {totalNumber}
                  </div>
                )}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export { GoodsColor };
