import { Button, Form, Input, Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { api, PUBLIC_IMAGE_URL } from '../../utils';
import { TopBar } from '../components';
import { Footer } from '../components/footer';
import { FooterLogo } from '../components/footerLogo';
import { LOGIN_BTNS } from './constant';
import styles from './index.less';
import { Store } from './store';

const { Item } = Form;

export const store = new Store();

const Login = observer(() => {
  return (
    <div className={styles.page}>
      <TopBar/>
      <div
        className={styles.bg}
        id="validCodeWrap"
        style={{ backgroundImage: `url(${PUBLIC_IMAGE_URL}logBg.png)` }}
      >
        <div className={styles.contentPart}>
          <div className={styles.loginWrapper}>
            <LoginContent store={store}/>
          </div>
        </div>
      </div>
      <FooterLogo/>
      <Footer/>
    </div>
  );
});

export const LoginContent: React.FC<{ store: Store; modalLogin?: boolean; }> = observer((props) => {
  const {
    tabKey,
    isSendCode,
    showImageCode,
    countDown,
    toggleLoginFlag,
    changeTab,
    loginRef,
    login,
    authimageneed,
    validateImage,
    changeAuthImage,
    changeModalLogin,
    gotoFindPassword,
    loginWechat,
  } = props.store;
  const { modalLogin } = props;

  // 登录成功的操作判定
  useEffect(() => {
    changeModalLogin(modalLogin);
  }, [modalLogin]);

  return (
    <div className={styles.login}>
      {/* <div
        className={styles.title}
      >
        欢迎登录衫海精选款平台
      </div> */}
      <div className={styles.tabWrap}>
        <Tabs
          activeKey={tabKey}
          items={LOGIN_BTNS}
          onChange={changeTab}
        />
      </div>
      <Form ref={loginRef}>
        <Item
          name="username"
          rules={[
            {
              required: true,
              message: '请输入账号',
            },
          ]}
        >
          <Input
            placeholder={(tabKey === 'password') ? '账号' : '手机号'}
            prefix={(
              <img
                className={styles.logIcon}
                src={`${PUBLIC_IMAGE_URL}account.png`}
              />
            )}
          />
        </Item>
        {(tabKey === 'password') && (
          <Item
            name="password"
            rules={[
              {
                required: true,
                message: '请输入密码',
              },
            ]}
          >
            <Input.Password
              onPressEnter={login}
              placeholder="密码"
              prefix={(
                <img
                  className={styles.logIcon}
                  src={`${PUBLIC_IMAGE_URL}password.png`}
                />
              )}
            />
          </Item>
        )}
        {(tabKey === 'validCode') && (
          <div
            className={styles.validCodeWrap}
          >
            <Item
              name="smsCode"
              rules={[
                {
                  required: true,
                  message: '请输入验证码',
                },
              ]}
            >
              <Input
                onPressEnter={login}
                placeholder="验证码"
                prefix={(
                  <img
                    className={styles.logIcon}
                    src={`${PUBLIC_IMAGE_URL}password.png`}
                  />
                )}
                style={{ width: '100%' }}
              />
            </Item>
            <Button
              className={styles.sendValidCode}
              disabled={isSendCode}
              onClick={showImageCode.bind(this, 'validCodeWrap', 0, 0)}
            >
              {isSendCode ? `${countDown}S后重新获取` : '获取验证码'}
            </Button>
          </div>
        )}
        {(tabKey === 'password' && authimageneed) && (
          <div className={styles.authWrapper}>
            <Item
              name="authImage"
              rules={[
                {
                  required: true,
                  message: '请录入图片验证码!',
                },
              ]}
            >
              <Input
                placeholder="验证码，不区分大小写"
              />
            </Item>
            <img
              onClick={changeAuthImage}
              src={`${api.getAuthImage}?${validateImage}`}
              title="看不清，换一张"
            />
          </div>
        )}
      </Form>
      {tabKey === 'password' && (
        <div className={styles.loginTip}>
          <div
            className={styles.link}
            onClick={() => {
              toggleLoginFlag(false);
            }}
          >
            免费注册
          </div>
          <div
            className={styles.link}
            onClick={gotoFindPassword}
            style={{ color: '#6D6E78' }}
          >
            忘记密码？
          </div>
        </div>
      )}
      <Button
        className={styles.buttonStyle}
        danger
        onClick={login}
        type="primary"
      >
        登录
      </Button>

      <div className={styles.otherLogin}>
        其他登录方式
        <img
          alt=""
          className={styles.loginIcon}
          onClick={() => {
            loginWechat();
          }}
          src={`${PUBLIC_IMAGE_URL}wechat.png`}
        />
      </div>
    </div>
  );
});

export default Login;
