import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { BaseData, PaginationData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import type { MessageContentVO, NotReadMessageStatistics, UserModel } from './interface';
import { API } from './api';
import { api } from '../../../../utils';
import PaginationStore from '../../pagination/store';

export default class NotificationContentStore {
  @observable public messageType = '全部'; // 1.订单通知 2.售后单

  @observable public loading = false;

  @observable public page = 1;

  @observable public pageSize = 20;

  @observable public total = 0;

  @observable public totalPageCount = 0;

  @observable public data: MessageContentVO[] = [];

  @observable public readAll = false; // 是否已发送全部已读请求

  @observable public isRead = false;

  @observable public systemType = 1000100;// 1000000 衫海精灵PC 1000100 衫海精 1000200 拿货pc

  @observable public userInfo: UserModel;

  @observable public paginationStore = new PaginationStore({ parent: this });

  @observable public parent = null;

  @observable public messageTypeList: Array<{ label: string; count: number; }> = [
    {
      label: '全部',
      count: 0,
    },
  ];

  @observable public queryConditions = {
    page: 1,
    pageSize: 20,
    sidx: '',
    sord: '',
    type: undefined,
  };

  constructor(systemType, parent) {
    if (systemType !== 0) {
      this.systemType = systemType;
      this.querySystemGroup();
    }
    if (parent) {
      this.parent = parent;
    }
    this.getUserInfo();
  }

  @action public getUserInfo = () => {
    request<UserModel>({ url: api.user })
      .then((res) => {
        this.userInfo = res;
      });
  };

  /**
   * 查询系统下group，即通知列表左侧栏目
   */
  @action public querySystemGroup = () => {
    request<BaseData<string[]>>({
      url: API.getSystemGroup,
      params: { systemType: this.systemType },
    })
      .then((res) => {
        const results = res.data?.map((item) => {
          return {
            label: item,
            count: 0,
          };
        }) || [];
        this.messageTypeList = this.messageTypeList.concat(results);
        this.onChangeActiveTab('全部');
        this.getUnreadListNum();
      });
  };

  @action public getUnreadListNum = (): void => {
    request<BaseData<NotReadMessageStatistics>>({
      url: API.getNotReadMessageStatistics,
      data: { systemType: this.systemType },
      method: 'POST',
    })
      .then((res) => {
        let messageTypeList = this.messageTypeList;
        messageTypeList = this.messageTypeList.map((item) => {
          if (item.label === '全部') {
            item.count = res.data.total;
            return item;
          }
          const it = res.data?.groupNumMap[item.label];
          item.count = it;
          return item;
        });
        this.messageTypeList = messageTypeList;
      });
  };

  @action public onChangeActiveTab = (activeKey: string): void => {
    this.messageType = activeKey;
    this.page = 1;
    this.paginationStore.resetPage();
    this.queryMessageList();
  };

  @action public queryMessageList = async(params?): Promise<void> => {
    this.loading = true;
    const res = await request<PaginationData<MessageContentVO>>({
      url: API.getMessageList,
      method: 'POST',
      data: {
        group: this.messageType === '全部' ? '' : this.messageType,
        page: this.page,
        pageSize: this.pageSize,
        onlyNotRead: this.isRead,
        systemType: this.systemType,
        ...params,
      },
    });
    this.loading = false;
    this.total = res.data.totalCount;
    this.data = res.data.list;
    this.totalPageCount = res.data.totalPageCount;
  };

  @action public onPaginationChange = (page: number, pageSize: number): void => {
    this.page = page;
    this.pageSize = pageSize;
    this.queryMessageList();
  };

  @action public changeCheckBox = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    this.isRead = checked;
    const params = {
      onlyNotRead: checked,
      page: 1,
      pageSize: this.pageSize,
    };
    this.paginationStore.resetPage();
    this.queryMessageList(params);
  };

  @action public setAllRead = () => {
    this.hasReadMessage(null);
  };

  @action public hasReadMessage = async(msgId: number): Promise<void> => {
    await request({
      url: API.batchReadMsg,
      params: {
        msgId,
        systemType: this.systemType,
      },
      method: 'PUT',
    });
    this.page = 1;
    this.paginationStore.resetPage();
    this.queryMessageList({
      page: 1,
      pageSize: this.pageSize,
    });
    this.getUnreadListNum();
    this.parent?.getUnReadMessage();
  };

  @action public onPageChange = (page: number, pageSize: number): void => {
    this.queryMessageList({
      page,
      pageSize,
    });
  };

  @action public onShowSizeChange = (page = 1, pageSize: number): void => {
    this.queryMessageList({
      page,
      pageSize,
    });
  };
}
