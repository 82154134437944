import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import styles from './index.less';

const gotoPage = () => {
  window.location.assign('/egenie-ts-vogue/homePage/index');
};

// 返回登录
export const TopBar = () => {
  return (
    <div
      className={styles.topBar}
      onClick={() => {
        gotoPage();
      }}
    >
      <img
        className={styles.topLogo}
        src={`${PUBLIC_IMAGE_URL}topLogo.png`}
      />
      <div
        className={styles.link}
        onClick={() => {
          gotoPage();
        }}
      >
        返回衫海精首页
        {'>>'}
      </div>
    </div>
  );
};
