export const LOGIN_BTNS = [
  {
    label: '密码登录',
    key: 'password',
  },
  {
    label: '验证码登录',
    key: 'validCode',
  },
];

export const APP_ID = 'wxa4a94e5aaa4b41f2';

export const REDIRECT_URL = 'https://shj.ejingling.cn/api/iam/login/anon/wechat/callback/pc_shj';

