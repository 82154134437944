import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './index.less';
import type { Params } from './types';

/**
 * @param imgUrl 图片链接
 * @param mouseRadius 方块半径; 默认102.5px
 * @param block 图片容器的宽高; 默认[410, 410]
 * @param scale 图片放大的倍数; 默认2倍
 * @returns
 */
export const PictureMagnifier: React.FC<Partial<{
  imgUrl: string;
  mouseRadius: number;
  block: [number, number];
  scale: number;
  status: number;
}>> = (props) => {
  const { mouseRadius, block, imgUrl, scale, status } = props;

  // 存参数
  const params = useRef<Params>({
    scale: 2,
    mouseRadius: 102.5,
    block: {
      width: 410,
      height: 410,
    },
  });

  // 鼠标ref
  const mouseRef = useRef<HTMLDivElement>(null);

  // 图片ref
  const imgRef = useRef<HTMLImageElement>(null);

  // 移入移出开关
  const [
    magnifierSwitch,
    setMagnifierSwitch,
  ] = useState(false);

  const calculationBlock = (offsetX: number, offsetY: number) => {
    const { block, mouseRadius, scale } = params.current;
    let offsetW = offsetX;
    let offsetH = offsetY;

    // 判断与左右的边距
    if (offsetX < params.current.mouseRadius) {
      offsetW = mouseRadius;
    } else if (offsetX > (block.width - mouseRadius)) {
      offsetW = (block.width - mouseRadius);
    }

    // 判断 鼠标小方块 与上下的边距
    if (offsetY < mouseRadius) {
      offsetH = mouseRadius;
    } else if (offsetY > (block.height - mouseRadius)) {
      offsetH = (block.height - mouseRadius);
    }

    const left = offsetW - mouseRadius;
    const top = offsetH - mouseRadius;

    // 设置鼠标悬停小方块
    if (mouseRef.current) {
      mouseRef.current.style.left = `${left }px`;
      mouseRef.current.style.top = `${top }px`;
    }

    /* 计算图片放大位置 */
    imgRef.current.style.left = `${-left * scale }px`;
    imgRef.current.style.top = `${-top * scale }px`;
  };

  const mouseOptions = useCallback((option: 'enter' | 'leave' | 'move', event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { offsetX, offsetY } = event?.nativeEvent;
    switch (option) {
      case 'enter':
        setMagnifierSwitch(true);
        break;
      case 'leave':
        setMagnifierSwitch(false);
        break;
      case 'move':
        calculationBlock(offsetX, offsetY);
    }
  }, []);

  // 更新参数
  useEffect(() => {
    params.current = {
      scale: scale || 2,
      mouseRadius: mouseRadius || 102.5,
      block: {
        width: block ? block[0] : 410,
        height: block ? block[1] : 410,
      },
    };
  }, [props]);

  return (
    <div className={styles.magnifierContainer}>
      <div
        className={styles.imageContainer}
      >
        <img
          alt="图片加载失败"
          className={styles.mainPic}
          src={imgUrl}
        />
        {status !== 3 ? (
          <div className={styles.solded}>
            <div className={styles.soldTip}>
              抱歉，该商品已下架
            </div>
          </div>
        ) : null}
        {magnifierSwitch && (
          <div
            className={styles.mouseBlock}
            ref={mouseRef}
            style={{
              width: params.current.mouseRadius * 2,
              height: params.current.mouseRadius * 2,
            }}
          />
        )}
      </div>
      <div
        className={styles.maskBlock}
        onMouseEnter={(e) => {
          mouseOptions('enter', e);
        }}
        onMouseLeave={(e) => {
          mouseOptions('leave', e);
        }}
        onMouseMove={(e) => {
          mouseOptions('move', e);
        }}
      />
      {magnifierSwitch && (
        <div className={styles.magnifierMemo}>
          <img
            alt="图片加载失败"
            className={styles.imgStyle}
            ref={imgRef}
            src={imgUrl}

            style={
              { transform: `scale(${params.current.scale})` }
            }
          />
        </div>
      )}
    </div>
  );
};
