import { Button, Popover } from 'antd';
import type { BaseData, FilterItemOptions } from 'egenie-utils';
import { ImgFormatter, request } from 'egenie-utils';
import React from 'react';
import cx from 'classnames';
import noPic from '../../assets/images/noPic.png';
import { getPlatformTypeIcon } from '../../utils';
import type DrawerStore from './drawer/store';
import styles from './index.less';
import type Store from './store';

export const PRODUCT_LINK = {
  '1': (id: string | number) => {
    return `https://item.taobao.com/item.htm?ft=t&id=${id}`;
  },
  '-100': (id: string | number) => {
    return `https://detail.tmall.com/item.htm?id=${id}`;
  },
  '21': (id: string | number) => {
    return `https://haohuo.jinritemai.com/views/product/detail?id=${id}`;
  },
  '17': (id: string | number) => {
    return `https://mms.pinduoduo.com/goods/goods_detail?goods_id=${id}`;
  },
  '22': (id: string | number) => {
    return `https://app.kwaixiaodian.com/merchant/shop/detail?id=${id}`;
  },
  '32': (id: string | number) => {
    return null;
  },
};

export const SHELVE_URL = {
  '-100': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesNew/index?platformType=-100&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '1': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesNew/index?platformType=1&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '21': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesDouyin/index?platformType=21&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '17': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesPdd/index?platformType=17&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '22': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesKs/index?platformType=22&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '32': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesDouyinProvider/index?platformType=32&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '31': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesWxVideo/index?platformType=31&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },

};

export const FILTER_ITEMS = (context: Store): FilterItemOptions[] => {
  return [
    {
      field: 'publishShopName',
      type: 'select',
      label: '铺货店铺',
      mode: 'multiple',
    },
    {
      field: 'vendorShopId',
      type: 'select',
      label: '档口',
    },
    {
      field: 'chooserId',
      type: 'select',
      label: '选款人',
    },

    {
      type: 'dateRange',
      field: 'createTime',
      label: '加入时间',
      format: 'YYYY-MM-DD',
    },
    {
      field: 'styleNo',
      type: 'input',
      label: '货号',
    },
    {
      field: 'styleName',
      type: 'input',
      label: '款式名称',
    },
    {
      field: 'styleSaleStatus',
      type: 'select',
      label: '供应商销售状态',
      data: styleSaleStatusOptions,
    },
    {
      field: 'putAwayTime',
      type: 'dateRange',
      label: '最近上架时间',
      format: 'YYYY-MM-DD',
    },
    {
      field: 'authorized',
      type: 'select',
      label: '图片授权',
      data: authorizedOptions,
    },
    {
      field: 'styleCategoryId',
      type: 'cascader',
      label: '类目',
      loadData: context.loadCategoryList,
    },
  ];
};

const styleSaleStatusOptions = [
  {
    label: '全部',
    value: null,
  },
  {
    label: '供应商在售',
    value: '3',
  },
  {
    label: '供应商下架',
    value: '4',
  },
];

const authorizedOptions = [
  {
    label: '待确认',
    value: '0',
  },
  {
    label: '有授权',
    value: '1',
  },
  {
    label: '无授权',
    value: '2',
  },
];

const authorizedDict = {
  '0': '待确认',
  '1': '有授权',
  '2': '无授权',
};

export const COLUMNS = (context: Store) => {
  return [
    {
      key: 'operate',
      name: '操作',
      width: 100,
      frozen: true,
      formatter: ({ row }) => {
        return (
          <div className={styles.cellWrapper}>
            <Button
              onClick={() => {
                context.openLaunch(row.posGoodsId);
              }}
              size="small"
              type="link"
            >
              一键铺货
            </Button>
            <Button
              onClick={() => {
                context.openDownload(row.posGoodsId);
              }}
              size="small"
              type="link"
            >
              下载素材
            </Button>
            <Button
              onClick={() => {
                context.drawerStore.openDrawer(row);
              }}
              size="small"
              type="link"
            >
              铺货记录
            </Button>
            <Button
              onClick={() => {
                context.checkRemove(row.gmsStyleId);
              }}
              size="small"
              type="link"
            >
              移出
            </Button>
          </div>
        );
      },
    },
    {
      key: 'styleInfo',
      name: '款式信息',
      frozen: true,
      formatter: ({ row }) => {
        return (
          <div className={styles.cellWrapper}>
            <div className={styles.styleWrapper}>
              {
                row.mainPicUrl ? (
                  <ImgFormatter
                    height={56}
                    value={row.mainPicUrl}
                    width={56}
                  />
                ) : (
                  <img
                    className={styles.imgStyle}
                    src={noPic}
                  />
                )
              }

              <div className={styles.styleInfo}>
                <div
                  className={styles.styleDetail}
                  onClick={() => {
                    const isProd = window.location.host.includes('dap');
                    const host = isProd ? 'https://shj.ejingling.cn' : '';
                    window.open(`${host}/egenie-ts-vogue/goodsDetail/index?goodsId=${row.posGoodsId}`);
                  }}
                >
                  {row.styleName}
                  <i className="icon-sq"/>
                </div>
                <div className={styles.styleDetailWrapper}>
                  <div className={row.status == 3 ? styles.styleOnSale : styles.stylePutAway}>
                    {row.status == 3 ? '供应商在售' : '供应商下架'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      key: 'styleNo',
      name: '货号',
      width: 200,
    },
    {
      key: 'authorized',
      name: '图片授权',
      width: 80,
      formatter: ({ row }) => {
        return (
          <span>
            {authorizedDict[row.authorized] || ''}
          </span>
        );
      },
    },
    {
      key: 'publishPlatformDetailList',
      name: '铺货店铺',
      width: 300,
      formatter: ({ row }) => {
        // 根据顶部状态做铺货店铺商品的过滤
        const selectedStatus = statusDict[context.activeStatus].value;
        let filteredGoodsList;

        // 全部的情况无需过滤
        if (!selectedStatus) {
          filteredGoodsList = row.shopGoodsList;
        } else {
          filteredGoodsList = row.shopGoodsList?.filter((item) => {
            return selectedStatus.some((selectedStatusValue) => item.salesStatus === selectedStatusValue);
          });
        }

        const displayGoodsList = filteredGoodsList?.slice(0, 3) || [];
        return (
          <div className={styles.goodsListWrapper}>
            {
              displayGoodsList?.map((item) => (
                <div
                  className={styles.gmsGoodsItem}
                  key={row.gmsGoodsId}
                >
                  <div className={styles.gmsShopWrapper}>
                    <img
                      alt=""
                      className={styles.icon}
                      onClick={() => {
                        context.drawerStore.openDrawer(row);
                      }}
                      src={getPlatformTypeIcon(Number(item.platformType))}
                    />
                    <div
                      className={styles.shopName}
                      onClick={() => {
                        context.drawerStore.openDrawer(row);
                      }}
                    >
                      {item.shopName}
                    </div>
                    <span className={saleStatusColorMap[item.salesStatus]}>
                      (
                      {saleStatusDict[item.salesStatus]}
                      )
                    </span>
                  </div>
                  {
                    (item.salesStatus === 1 || item.salesStatus === 4) ? (
                      <Button
                        className={`${styles.mainGridShelve} ${styles.unShelveButton}`}
                        disabled={item.salesStatus === 4}
                        onClick={() => {
                          context.drawerStore.handleProductPutaway(item.gmsGoodsId);
                        }}
                        size="small"
                      >
                        下架
                      </Button>
                    ) : (
                      <Button
                        className={styles.mainGridShelve}
                        disabled={item.salesStatus === 3}
                        onClick={() => {
                          context.drawerStore.handleOnShelve(item.shopId, item.platformType, item.gmsGoodsId, item.salesStatus);
                        }}
                        size="small"
                        type="primary"
                      >
                        {item.salesStatus === 3 ? '上架中' : '上架'}
                      </Button>
                    )
                  }
                </div>
              ))
            }
            {
              ((row.shopGoodsList?.length > 3) || (filteredGoodsList?.length < row.shopGoodsList?.length)) && (
                <a
                  onClick={() => {
                    context.drawerStore.openDrawer(row);
                  }}
                >
                  更多店铺
                </a>
              )
            }
          </div>
        );
      },
    },
    {
      key: 'vendorShopName',
      name: '档口',
      width: 100,
    },
    {
      key: 'costPriceStr',
      name: '最新供货价',
      width: 50,
    },
    {
      key: 'stockNum',
      name: '供货库存',
      width: 50,
    },
    {
      key: 'chooser',
      name: '选款人',
      width: 50,

    },
    {
      key: 'createTime',
      name: '加入时间',
      width: 160,
    },
  ];
};

const PopoverStrategy: React.FC<{ strategyList; }> = (props) => {
  const { strategyList } = props;
  return (
    <div>
      {
        Array.isArray(strategyList) && strategyList.map((el) => (
          <div
            className={styles.strategyPop}
            key={el.gmsGoodsStrategyId}
          >
            <div>
              {el.name}
            </div>
          </div>
        ))
      }
    </div>
  );
};

const StrategyContent: React.FC<{ strategyList;num; }> = (props) => {
  const { strategyList } = props;
  return (
    <div>
      {strategyList?.length > 0 ? strategyList.map((item) => (
        <div key={item.gmsStrategyId}>
          {item.name}
        </div>
      )) : ''}
      {
        strategyList?.length > 2 && (
          <div>
            ...
          </div>
        )
      }
    </div>
  );
};

export const DRAWER_COLUMNS = (context: DrawerStore) => {
  return [
    {
      key: 'operate',
      name: '操作',
      width: 80,
      formatter: ({ row }) => {
        return (
          <div className={styles.cellWrapper}>
            <Button
              onClick={() => {
                context.productAnalysisStore.openModal(row, 1);
              }}
              size="small"
              type="link"
            >
              商品数据
            </Button>
            <Button
              onClick={() => {
                context.productAnalysisStore.openModal(row, 2);
              }}
              size="small"
              type="link"
            >
              策略任务
            </Button>
          </div>
        );
      },
      ejlHidden: true,
      frozen: true,
    },
    {
      key: 'productInfo',
      name: '商品信息',
      width: 220,
      formatter: ({ row }) => {
        return (
          <div className={styles.cellWrapper}>
            <div className={styles.styleWrapper}>
              <ImgFormatter
                height={56}
                value={row.mainPicUrl}
                width={56}
              />
              <div className={styles.styleInfo}>
                <div>
                  {row.goodsName}
                </div>
                <div>
                  {row.goodsCategoryName}
                </div>
                <div>
                  平台商品ID:
                  {row.platformId}
                </div>
              </div>
            </div>
          </div>
        );
      },
      frozen: true,
    },
    {
      key: 'shopName',
      name: '铺货店铺',
      formatter: ({ row }) => {
        return (
          <div className={styles.cellWrapper}>
            <div className={`${styles.mb8} ${styles.shopWrapper}`}>
              <img
                alt=""
                className={styles.icon}
                src={getPlatformTypeIcon(row.shopPlatformType)}
              />
              <div className={styles.shopText}>
                {row.shopName}
              </div>
            </div>
            {
              row.salesStatus === 1 ? (
                <Button
                  className={`${styles.shelvesButton} ${styles.unShelve}`}
                  onClick={() => {
                    context.handleProductPutaway(row.gmsGoodsId);
                  }}
                  type="link"
                >
                  下架
                </Button>
              ) : (
                <Button
                  className={styles.shelvesButton}
                  disabled={row.salesStatus === 3}
                  onClick={() => {
                    context.handleOnShelve(row.shopId, row.shopPlatformType, row.gmsGoodsId, row.salesStatus);
                  }}
                  type="link"
                >
                  上架
                </Button>
              )
            }
          </div>
        );
      },
    },
    {
      key: 'salesStatusName',
      name: '上架状态',
      width: 40,
      formatter: ({ row }) => {
        return (
          <div
            className={styles.cellWrapper}
            style={{ whiteSpace: 'normal' }}
          >
            <div className={cx(styles.mb4, { [styles.red]: row.salesStatus === 5 })}>
              {row.salesStatusName}
            </div>
            <Popover
              content={row.putOnShelfFailMsg}
            >
              <div className={styles.failedMsg}>
                {row.putOnShelfFailMsg}
              </div>
            </Popover>
            {
              row.platformId && typeof (PRODUCT_LINK[row.shopPlatformType]) === 'function' && PRODUCT_LINK[row.shopPlatformType](row.platformId) && (
                <div
                  className={styles.productLink}
                  onClick={
                    () => {
                      window.top.open(PRODUCT_LINK[row.shopPlatformType](row.platformId));
                    }
                  }
                >
                  宝贝链接
                </div>
              )
            }
          </div>
        );
      },
    },
    {
      key: 'goodsStrategyList',
      name: '商品策略',
      width: 80,
      formatter: ({ row }) => {
        const displayStrategyList = row.goodsStrategyList?.filter((item) => item.status === 2);
        const strategyNum = displayStrategyList?.length || 0;
        const displayStrategy = displayStrategyList?.slice(0, 2) || [];
        return (
          <div className={styles.cellWrapper}>
            {
              strategyNum > 2 ? (
                <Popover
                  arrowPointAtCenter
                  content={<PopoverStrategy strategyList={displayStrategyList}/>}
                  placement="rightBottom"
                >
                  <div>
                    {displayStrategy?.length > 0 ? displayStrategy.map((item) => (
                      <div key={item.gmsStrategyId}>
                        {item.name}
                      </div>
                    )) : ''}
                    {
                      strategyNum > 2 && (
                        <div>
                          ...
                        </div>
                      )
                    }
                  </div>
                </Popover>
              ) : (
                <div>
                  {
                    displayStrategy.map((item) => (
                      <div key={item.gmsStrategyId}>
                        {item.name}
                      </div>
                    ))
                  }
                </div>
              )
            }

            <div
              className={styles.productLink}
              onClick={() => {
                context.openStrategy(row, strategyNum ? 'edit' : 'new');
              }}
            >
              {strategyNum ? '编辑策略' : '分配策略'}
            </div>
          </div>
        );
      },
    },
    {
      key: 'salePrice',
      name: '售价',
      width: 80,
    },
    {
      key: 'putAwayTime',
      name: '最近上架时间',
    },
  ];
};

// 上架状态对应文案（待上架和上架中都属于待上架 已下架对应仓库中）
const saleStatusDict = {
  '0': '待上架',
  '1': '在售',
  '2': '仓库中',
  '3': '上架中',
  '4': '下架中',
  '5': '上架失败',
};

const saleStatusColorMap = {
  '3': styles.green,
  '4': styles.colorFF5C4D,
  '5': styles.red,
};

export const commonDict = {
  commonDictList: [
    'vendorShopList',
    'salesStatusList',
    'chooserList',
    'shopList',
  ],
};

export const statusDict = {
  allNum: {
    label: '全部',
    value: null,
  },
  offNum: {
    label: '仓库中',
    value: [2],
  },
  onNum: {
    label: '在售',
    value: [1],
  },
  waitNum: {
    label: '待上架',
    value: [
      0,
      3,
    ],
  },
  failNum: {
    label: '上架失败',
    value: [5],
  },
};

// 分级查询类目
export const categoryRequest = async(parentId: string) => {
  const { data } = await request<BaseData<Array<{
    categoryId: string;
    categoryIdName: string;
    leaf: boolean;
  }>>>({
    url: '/api/gms/pc/common/getCategoryList',
    method: 'POST',
    data: {
      categoryType: 25, // 固定参数
      parentCategoryId: parentId,
    },
  });
  if (Array.isArray(data)) {
    return data.map((i) => ({
      value: String(i.categoryId), // 组件需要string
      label: i.categoryIdName,
      isLeaf: i.leaf,
    }));
  }
  return [];
};

// 批量上架页面URL
export const BATCH_SHELVE_URL = {
  '17': (ids: string, shopIds: string, pageId: string) => {
    return `/egenie-ts-vogue/batchShelvesPdd/index?ids=${ids}&shopIds=${shopIds}&pageId=${pageId}`;
  },
};

