import { observer } from 'mobx-react';
import React from 'react';
import { ProductCard } from '../../../components';
import styles from './index.less';
import { isDistributor } from '../../../../utils';

export const SimilarStyle = observer((props) => {
  const {
    similarStyleList,
    rightSiderStore,
  } = props;
  const isDistributorType = isDistributor();
  if (isDistributorType) {
    return null;
  }
  return (
    <div className={styles.similarStyle}>
      <div className={styles.header}>
        相似推荐款
      </div>
      <div className={styles.picWrap}>
        {
          similarStyleList?.map((item) => {
            return (
              <div
                key={item.goodsId}
                style={{ marginTop: 8 }}
              >
                <ProductCard
                  onClick={() => {
                    window.open(`/egenie-ts-vogue/goodsDetail/index?goodsId=${item.goodsId}`);
                  }}
                  {...item}
                  callback={() => {
                    rightSiderStore.getMyStyleWaitNum();
                  }}
                />
              </div>
            );
          })
        }
      </div>
    </div>
  );
});
