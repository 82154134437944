import styleWechatIcon from '../../../../assets/images/wechat_green.png';
import styles from './index.less';
import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { api } from '../../../../../src/utils/api';

const Wechat = ({ moduleType }: { moduleType: string; }) => {
  const [
    tipVisible,
    setTipVisible,
  ] = useState(false);
  const [
    qrCodeUrl,
    setQrCodeUrl,
  ] = useState('');
  useEffect(() => {
    const req = async() => {
      const res = await request<BaseData<string>>({
        url: api.queryCustomerService,
        params: { moduleType },
      });
      setQrCodeUrl(res.data);
    };
    if (moduleType) {
      req();
    }
  }, [moduleType]);

  return (
    <div className={styles.wechat}>
      <img
        alt=""
        className={styles.wechatIcon}
        src={styleWechatIcon}
      />
      <span
        className={styles.wechatText}
        onMouseEnter={() => setTipVisible(true)}
        onMouseLeave={() => setTipVisible(false)}
      >
        款式咨询
      </span>
      <WechatTips
        imageUrl={qrCodeUrl}
        setTipVisible={setTipVisible}
        visible={tipVisible}
      />
    </div>
  );
};
export default Wechat;

const WechatTips: React.FC<{
  visible: boolean;
  setTipVisible: Dispatch<SetStateAction<boolean>>;
  imageUrl: string;
}> = (props) => {
  const {
    visible,
    setTipVisible,
    imageUrl,
  } = props;
  return (
    <div
      className={styles.tipsPopover}
      style={{ display: `${visible ? 'block' : 'none'}` }}
    >
      <header className={styles.header}>
        <span className={styles.tipsTitle}>
          请微信扫码咨询库存/合作等信息
        </span>

      </header>
      <div className={styles.tipsContent}>
        <img
          className={styles.qrCode}
          src={imageUrl}
        />
      </div>
    </div>
  );
};
