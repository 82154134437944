import { action, observable } from 'mobx';

export default class Store {
  constructor(options) {
    this.parent = options.parent;
  }

  @observable public parent;

  @observable public current = 1;

  @observable public pageSize = 50;

  @observable public totalCount = 0;

  @observable public totalPageCount = 0;
  
  // 分页跳转
  @action public onPageChange = (page: number, pageSize: number): void => {
    this.current = page;
    this.parent.onPageChange(page, pageSize);
  };

  // 页数变化回调
  @action public onShowSizeChange = (page: number, pageSize: number): void => {
    this.current = page;
    this.pageSize = pageSize;
    this.parent.onShowSizeChange(page, pageSize);
  };

  // 重置分页
  @action public resetPage = (): void => {
    this.current = 1;
  };
}
