import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request, singleDownload } from 'egenie-common';
import { action, flow, observable } from 'mobx';
import shortid from 'shortid';
import { api } from '../../../../utils';

interface IGmsGoodsGroupPicDownloadVo {
  groupId: null;
  groupName: string;
  mainPicUrls: string[];
  smallPicUrl: string;
}

interface IPosGoodsPicDownloadVos {
  groupId: number;
  groupName: string;
  packageName: string;
  smallPicUrl: string | null;
  videoUrl: string;
  type?: 'video' | 'picture';
}

interface GroupPicture {
  gmsGoodsGroupPicDownloadVo?: IGmsGoodsGroupPicDownloadVo;
  posGoodsPicDownloadVos?: IPosGoodsPicDownloadVos[];
}

export default class DownloadStore {
  constructor(options) {
    this.parent = options.parent;
  }

  @observable public loading = false;

  @observable public visible = false;

  @observable public parent;

  @observable public goodsId: number;

  @observable public frontOrBack: 'front' | 'back';

  @observable public loadingGroupIds: number[] = [];// 展示后端打包loading的groupId

  @observable public groupVideoAndPics: IPosGoodsPicDownloadVos[] = []; // 展示的组图信息

  @observable public dynamicIds: unknown;

  @action public onModalShow = (goodsId: number, dynamicIds: unknown, frontOrBack: 'front' | 'back' = 'back') => {
    Object.assign(this, {
      visible: true,
      goodsId,
      dynamicIds,
      frontOrBack,
    });

    this.getGroupPic(); // 获取主图信息
  };

  @action public onModalClose = () => {
    this.visible = false;
    this.groupVideoAndPics = [];
  };

  @action public getGroupPic = async(): Promise<void> => {
    this.loading = true;
    if (typeof this.parent.downloadLoading === 'boolean') {
      this.parent.downloadLoading = true;
    }

    const res: BaseData<IPosGoodsPicDownloadVos[]> = await request({ url: `${api.getPicDownloadInfo}?goodsId=${this.goodsId}` });
    try {
      this.groupVideoAndPics = res.data.map((el) => {
        return {
          ...el,
          type: el.videoUrl ? 'video' : 'picture',
        };
      });
    } catch (err) {
      console.log(err);
    }
    if (typeof this.parent.downloadLoading === 'boolean') {
      this.parent.downloadLoading = false;
    }
    this.loading = false;
  };

  @observable public getId = () => {
    const id = shortid.generate();
    if (shortid.isValid(id)) {
      return id;
    }
    this.getId();
  };

  // 下载图片包埋点
  @observable public recordDownloadNum = async(groupId: number, groupName: string | number): Promise<void> => {
    await request({
      url: api.recordDownloadNum,
      method: 'post',
      data: {
        dynamicIds: this.dynamicIds ? this.dynamicIds : '',
        goodsId: this.goodsId,
        groupId,
        groupName,
      },
    });
  };

  // 下载
  public downLoadPackage = flow(function* (groupId: number, type: 'picture' | 'video', groupName: string) {
    let downloadUrl = '';
    const item = this.groupVideoAndPics.find((el) => el.groupId === groupId);
    try {
      if (type === 'picture') {
        this.loadingGroupIds.push(groupId);
        const res = yield request<BaseData<string>>({
          url: '/api/mall/rest/goods/getGoodsPackageUrl',
          method: 'GET',
          params: {
            goodsId: this.goodsId,
            groupId,
          },
        });
        downloadUrl = res.data;
      } else {
        downloadUrl = item.videoUrl;
        if (!downloadUrl) {
          message.error('下载视频链接不存在');
          return;
        }
      }

      downloadUrl ? singleDownload({ obsConfigId: 4 }, downloadUrl) : message.error('下载图片链接不存在');

      this.recordDownloadNum(groupId, groupName); // 下载图片包埋点
    } catch (e) {
      console.log(e);
    } finally {
      this.loadingGroupIds = this.loadingGroupIds.filter((item) => item !== groupId);
    }
  });

  /**
   * 下载文件
   * @param content 文件内容
   * @param filename 文件名称
   */
  private downloadFile = (content: string, filename: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    downloadLink.download = filename;
    const blob = new Blob([content]);
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.click();
  };
}

export type IDownloadPicPackageStore = DownloadStore;
