/**
 * 固定侧边栏 暂时只包括：二维码信息和返回顶部按钮
 */
import { Popover } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { getStaticResourceUrl } from 'egenie-common';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import type { User } from 'egenie-common';
import { api, checkLoginStatus, isDistributor, LoginModal, PUBLIC_IMAGE_URL, replacePath, scrollToAnchor, useCheckLogin } from '../../../utils';
import { GuideModal } from './guideModal';
import styles from './index.less';

// 页面滑动到一定高度，展示回到顶部按钮
const PAGE_TOP = 300;

// 右侧固定导航
export const RightSider = observer((props) => {
  const [
    pageTop,
    setPageTop,
  ] = React.useState<number>(0);

  // FIXME: 加debonce，展示效果有影响
  const getPageScrollTop = debounce(() => {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop || 0;
    setPageTop(scrollTop);
  }, 50);

  const [
    loginStatus,
    setLoginStatus,
  ] = React.useState<boolean>(false);
  const isDistributorType = isDistributor();
  const init = async(): Promise<void> => {
    const loginStatus = await checkLoginStatus();
    setLoginStatus(loginStatus);
  };

  useEffect(() => {
    init();
    window.addEventListener('scroll', getPageScrollTop, true);
    return () => {
      window.removeEventListener('scroll', getPageScrollTop, true);
    };
  }, []);

  return (
    <div
      className={styles.rightSiderWrap}
      style={{
        right: 20,
        height: isDistributorType ? 234 : 312,
      }}
    >
      <MyStyle store={props.store}/>
      <ShoppingCart
        loginStatus={loginStatus}
        store={props.store}
      />
      {/* <Order loginStatus={loginStatus}/> */}
      <CustomerService/>
      {!isDistributorType && <NewGuide store={props.store}/>}
      {pageTop > PAGE_TOP && (
        <div
          className={`${styles.backTopWrap} fade-enter fade-enter-active fade`}
          onClick={() => {
            scrollToAnchor('root');
          }}
        >
          <i className={`icon-arrow_pulldown ${styles.pullUp}`}/>
        </div>
      )}
    </div>
  );
});

export const NewGuide = observer((props) => {
  const { toggleModal } = props.store || {};
  const [
    currentUrl,
    setCurrentUrl,
  ] = React.useState<string>('');
  useEffect(() => {
    setCurrentUrl(`${PUBLIC_IMAGE_URL}guide.png`);
  }, []);
  return (
    <>
      <div
        className={styles.entryWrap}
        id="newGuide"
        onClick={() => toggleModal(true)}
        onMouseEnter={() => {
          setCurrentUrl(`${PUBLIC_IMAGE_URL}guideHover.png`);
        }}
        onMouseLeave={() => {
          setCurrentUrl(`${PUBLIC_IMAGE_URL}guide.png`);
        }}
      >
        <BtnWrap
          title="新手引导"
          url={currentUrl}
        />
      </div>
      <GuideModal store={props.store}/>
    </>
  );
});
export const MobileEntry = observer((props) => {
  const [
    currentUrl,
    setCurrentUrl,
  ] = React.useState<string>('');
  useEffect(() => {
    setCurrentUrl(`${PUBLIC_IMAGE_URL}mobileUrl.png`);
  }, []);
  return (
    <Popover
      content={
        (
          <div className={styles.popoverImgWrap}>
            <div className={styles.imgWrap}>
              <img
                alt=""
                src={`${PUBLIC_IMAGE_URL}baokuanAppCode.png`}
              />
              <span>
                扫码下载APP
              </span>
            </div>
            <div className={styles.imgWrap}>
              <img
                alt=""
                src={`${PUBLIC_IMAGE_URL}shanHaiJinMiniProgramCode.jpg`}
              />
              <span>
                扫码打开小程序
              </span>
            </div>
          </div>
        )
      }
      getPopupContainer={() => document.getElementById('rightSider')}
      placement="left"
    >
      <div
        className={styles.entryWrap}
        id="rightSider"
        onMouseEnter={() => {
          setCurrentUrl(`${PUBLIC_IMAGE_URL}mobileHoverUrl.png`);
        }}
        onMouseLeave={() => {
          setCurrentUrl(`${PUBLIC_IMAGE_URL}mobileUrl.png`);
        }}
      >
        <BtnWrap
          title="手机版"
          url={currentUrl}
        />
      </div>
      <HorizenLine/>
    </Popover>
  );
});

export const HorizenLine = () => {
  return (
    <div className={styles.horizenLine}/>
  );
};

export const Order = observer((props) => {
  const [
    currentUrl,
    setCurrentUrl,
  ] = React.useState<string>('');
  useEffect(() => {
    setCurrentUrl(`${PUBLIC_IMAGE_URL}order.png`);
  }, []);

  const gotoMyOrder = async() => {
    if (!props.loginStatus) {
      window.location.assign('/egenie-ts-vogue/login');
    } else {
      const userInfo: User = await request({ url: api.user });
      if (userInfo.tenantType?.includes('100001')) {
        window.open(`${api.switchToErpHome}&jumpTo=myOrder`); // erp账户跳转到erp首页
      } else {
        window.open('/egenie-erp-home/shjHome?jumpTo=myOrder');
      }
    }
  };
  return (
    <>
      <div
        className={styles.entryWrap}
        onClick={gotoMyOrder}
        onMouseEnter={() => {
          setCurrentUrl(`${PUBLIC_IMAGE_URL}orderHover.png`);
        }}
        onMouseLeave={() => {
          setCurrentUrl(`${PUBLIC_IMAGE_URL}order.png`);
        }}
      >
        <BtnWrap
          title="订单"
          url={currentUrl}
        />
      </div>
      <HorizenLine/>
    </>

  );
});

export const MyStyle = observer((props) => {
  const {
    goPage,
    getMyStyleWaitNum,
    waitNum,
  } = props.store || {};
  const [
    loginStatus,
    setLoginStatus,
  ] = React.useState<boolean>(false);
  const [
    currentUrl,
    setCurrentUrl,
  ] = React.useState<string>('');

  const {
    checkLoginFn,
    visible,
    setVisible,
  } = useCheckLogin();
  const init = async(): Promise<void> => {
    const loginStatus = await checkLoginStatus();
    setLoginStatus(loginStatus);
    if (loginStatus) {
      getMyStyleWaitNum();
    }
  };
  useEffect(() => {
    setCurrentUrl(`${PUBLIC_IMAGE_URL}myStyleIcon.png`);
    init();
  }, [waitNum]);
  return (
    <>
      <div
        className={styles.entryWrap}
        onClick={() => {
          if (!loginStatus) {
            if (isDistributor()) {
              checkLoginFn(() => {
                goPage('myStyle');
              });
            } else {
              window.location.assign('/egenie-ts-vogue/login');
            }
          } else {
            goPage('myStyle');
          }
        }}
        onMouseEnter={() => {
          setCurrentUrl(`${PUBLIC_IMAGE_URL}myStyleIconHover.png`);
        }}
        onMouseLeave={() => {
          setCurrentUrl(`${PUBLIC_IMAGE_URL}myStyleIcon.png`);
        }}
      >
        <BtnWrap
          title="我的款式"
          url={currentUrl}
        />
        {(loginStatus && waitNum > 0) && (
          <div className={`${styles.shoppingNumCommon} ${waitNum > 99 ? styles.shoppingNumMore : styles.shoppingNum}`}>
            {waitNum > 99 ? '99+' : waitNum}
          </div>
        )}
      </div>
      <HorizenLine/>
      <LoginModal
        setVisible={setVisible}
        visible={visible}
      />
    </>
  );
});

export const ShoppingCart = observer((props) => {
  const [
    num,
    setNum,
  ] = React.useState<number>(0);
  const [
    loginStatus,
    setLoginStatus,
  ] = React.useState<boolean>(false);
  const getShoppingCartNum = async(): Promise<void> => {
    const res: BaseData<number> = await request({ url: api.getShoppingNumUrl });
    setNum(res.data);
  };
  const [
    currentUrl,
    setCurrentUrl,
  ] = React.useState<string>('');
  const {
    checkLoginFn,
    visible,
    setVisible,
  } = useCheckLogin();

  const init = async(): Promise<void> => {
    const loginStatus = await checkLoginStatus();
    setLoginStatus(loginStatus);
    if (loginStatus) {
      getShoppingCartNum();
    }
  };
  useEffect(() => {
    setCurrentUrl(`${PUBLIC_IMAGE_URL}shoppingCart.png`);
    init();
  }, []);
  return (
    <>
      <div
        className={styles.entryWrap}
        onClick={() => {
          if (!loginStatus) {
            if (isDistributor()) {
              checkLoginFn(() => {
                window.open(replacePath('/egenie-ts-vogue/shoppingCart/index'));
              });
            } else {
              window.location.assign('/egenie-ts-vogue/login');
            }
          } else {
            window.open(replacePath('/egenie-ts-vogue/shoppingCart/index'));
          }
        }}
        onMouseEnter={() => {
          setCurrentUrl(`${PUBLIC_IMAGE_URL}shoppingCartHover.png`);
        }}
        onMouseLeave={() => {
          setCurrentUrl(`${PUBLIC_IMAGE_URL}shoppingCart.png`);
        }}
      >
        <BtnWrap
          title="进货车"
          url={currentUrl}
        />
        {(loginStatus && num > 0) && (
          <div className={`${styles.shoppingNumCommon} ${num > 99 ? styles.shoppingNumMore : styles.shoppingNum}`}>
            {num > 99 ? '99+' : num}
          </div>
        )}
      </div>
      <HorizenLine/>
      <LoginModal
        setVisible={setVisible}
        visible={visible}
      />
    </>
  );
});

export const CustomerService = observer((props) => {
  const [
    currentUrl,
    setCurrentUrl,
  ] = React.useState<string>('');
  useEffect(() => {
    setCurrentUrl(`${PUBLIC_IMAGE_URL}customService.png`);
  }, []);
  return (
    <Popover
      content={
        (
          <div className={styles.popoverImgWrap}>
            <div
              className={styles.imgWrap}
              style={{ margin: 0 }}
            >
              <img
                alt=""
                src={getStaticResourceUrl('app/app-baokuan/assets/my/kefu.png')}
              />
            </div>

          </div>
        )
      }
      getPopupContainer={() => document.getElementById('customerService')}
      placement="left"
    >
      <div
        className={styles.entryWrap}
        id="customerService"
        onMouseEnter={() => {
          setCurrentUrl(`${PUBLIC_IMAGE_URL}customServiceHover.png`);
        }}
        onMouseLeave={() => {
          setCurrentUrl(`${PUBLIC_IMAGE_URL}customService.png`);
        }}
      >
        <BtnWrap
          title="客服"
          url={currentUrl}
        />
      </div>
      <HorizenLine/>
    </Popover>
  );
});

const BtnWrap = observer((props) => {
  const {
    url,
    title,
  } = props;
  return (
    <>
      <img
        alt=""
        src={url}
      />
      <span>
        {title}
      </span>
    </>
  );
});
