import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { api } from './api';

export const getImgUrl = (url: string): string => {
  return `https://egenie-frontend.oss-cn-hangzhou.aliyuncs.com/app/app-baokuan/assets/pcImages/${url}`;
};

export const scrollToAnchor = (anchorName: string): void => {
  if (anchorName) {
    const anchorElement = document.getElementById(anchorName);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
};

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');

  const bstr = atob(arr[1]);

  let n = bstr.length;

  const mime = arr[0].match(/:(.*?);/)[1],
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: 'image/png' });
};

export const escape2Html = (str: string): string => {
  const arrEntities = {
    lt: '<',
    gt: '>',
    nbsp: ' ',
    amp: '&',
    quot: '"',
    apos: '\'',
  };
  return str.replace(/&(lt|gt|nbsp|amp|quot|apos);/ig, (all, t) => {
    return arrEntities[t];
  });
};

// 分割整数和小数部分
export const splitPrice = (price: number | string): { intPart: string; decimalPart: string; } => {
  const _price = Number(price);
  if (_price == null) {
    return {
      intPart: '0',
      decimalPart: '00',
    };
  }
  const [
    intPart,
    decimalPart,
  ] = _price.toFixed(2)
    .split('.');
  return {
    intPart,
    decimalPart,
  };
};

// 后去时间间隔戳，转化为天、时、分、秒
export const getDateInterval = (startTime, endTime) => {
  const startTimeDate = new Date(startTime).getTime();
  const endTimeDate = new Date(endTime).getTime();
  let distance;
  let currentActivity = 'init';
  const nowTime = new Date().getTime();
  if (nowTime - startTimeDate < 0) {
    distance = startTimeDate - nowTime;
    currentActivity = 'start';
  } else if (endTimeDate - nowTime > 0) {
    distance = endTimeDate - nowTime;
    currentActivity = 'end';
  } else {
    currentActivity = 'over';
  }
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));

  // const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const hours = Math.floor((distance / (1000 * 60 * 60)));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return {
    days,
    hours,
    minutes,
    seconds,
    distance,
    currentActivity,
  };
};

export const getPrice = (price, discountPrice, activityPrice) => {
  const discountPriceTmp = discountPrice && `${discountPrice}`;
  const _discountPrice = discountPriceTmp && discountPriceTmp.replace(/,/g, '');// (discountPrice && discountPrice.replace) ? discountPrice.replace(/,/g, '') : discountPrice;
  if (price && _discountPrice && activityPrice) {
    return Math.min(Number(price), Number(_discountPrice), Number(activityPrice));
  }
  if (_discountPrice && activityPrice) {
    return Math.min(Number(_discountPrice), Number(activityPrice));
  }
  return activityPrice || Number(_discountPrice) || price;
};

// 获取字符串真实长度
export const getStrLen = (str) => {
  let len = 0;
  if (!str) {
    return len;
  }
  if (typeof str === 'string') {
    for (let i = 0; i < str.length; i++) {
      if ((str.charCodeAt(i) >= 0 && str.charCodeAt(i) < 65) || (str.charCodeAt(i) > 90 && str.charCodeAt(i) <= 128)) {
        len++;
      } else {
        len += 2;
      }
    }
  }
  return len;
};

// 截取maxLength长度的字符
export const getStr = (str, maxLength) => {
  const resultArr = [];
  let len = 0;
  if (getStrLen(str) < maxLength) {
    return str;
  }
  for (let i = 0; i < str.length; i++) {
    if ((str.charCodeAt(i) >= 0 && str.charCodeAt(i) < 65) || (str.charCodeAt(i) > 90 && str.charCodeAt(i) <= 128)) {
      len++;
    } else {
      len += 2;
    }
    if (len <= maxLength) {
      resultArr.push(str[i]);
    } else {
      break;
    }
  }
  return resultArr.join('');
};

// 校验大于等于0 且保留两位小数
export const limitDecimals = (value: string): string => {
  const reg = /^(-)*(\d+)\.(\d\d).*$/;
  const num = `${value}`.replace(reg, '$1$2.$3');
  if (isNaN(Number(num)) || Number(num) < 0) {
    return '';
  } else {
    return num;
  }
};

// 四舍五入保留两位小数
export const formatDecimal = (value: number | string): string => {
  const target = Number(value);
  return (Math.round(target * 100) / 100).toFixed(2);
};

// 获取当前登录状态
export const checkLoginStatus = async(): Promise<boolean> => {
  try {
    const res: BaseData<boolean> = await request({ url: api.loginCheck });
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(false);
  }
};

// 正则替换
export const replaceBar = (str) => {
  return str.replace(/-/g, '/');
};

export const setLocationHref = () => {
  sessionStorage.setItem('VogueLocationHref', window.location.href);
};

export const mapOptions = (arr: [], key, value) => {
  return Array.isArray(arr) ? arr.map((item) => ({
    label: item[value],
    value: item[key],
  })) : [];
};

/**
 * 计算时间差 返回值 X天X时X分X秒
 * @param 时间差秒数 number
 */
export const calcTimeDiffDisplay = (durationSeconds: number): string => {
  const daySeconds = 3600 * 24;
  const hourSeconds = 3600;
  const minuteSeconds = 60;

  // 计算天数
  const days = Math.floor(durationSeconds / daySeconds);
  const hours = Math.floor((durationSeconds - days * daySeconds) / hourSeconds);
  const minutes = Math.floor((durationSeconds - days * daySeconds - hours * hourSeconds) / minuteSeconds);

  return `${days}天${hours}小时${minutes}分`;
};

/**
 * 判断当前路径是否有关联店铺字符，如果有则是分销商，则新跳转的路径自动加上distributor字符
 */
export const replacePath = (originPath: string): string => {
  if (!originPath || originPath.length === 0) {
    return '';
  }
  const url = window.location.pathname;
  const reg = /\/distributor\//g;
  const isMatch = reg.test(url);
  if (isMatch) {
    const result = originPath.replace('/egenie-ts-vogue/', '/egenie-ts-vogue/distributor/');
    return result;
  }
  return originPath;
};

/**
 * 判断当前路径是否有关联店铺字符，如果有则是分销商
 */
export const isDistributor = () => {
  const url = window.location.pathname;
  const reg = /\/distributor\//g;
  const isMatch = reg.test(url);
  return isMatch;
};
