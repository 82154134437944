/**
 * 分页组件，使用antd的分页器，修改了公共样式
 */
import { Pagination } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { PAGE_SIZE_OPTIONS } from './constant';
import styles from './index.less';

interface IStore {
  current: number;
  onPageChange: (page: number, pageSize: number) => void;
  onShowSizeChange: (page: number, pageSize: number) => void;
}
interface IProps {
  store: IStore;
  totalPageCount: number;
  totalCount: number;
  customClassName?: string;
  pageSize?: number;
  showQuickJumper?: boolean;
}

// 公共分页
export const PaginationCus = observer((props: IProps) => {
  const { store: { current, onPageChange, onShowSizeChange }, pageSize = 50, totalPageCount, totalCount, customClassName, showQuickJumper = true } = props;

  return (
    <div className={`${styles.paginationCus} ${customClassName}`}>
      <div className={styles.info}>
        {
          totalPageCount != 0 && (
            <>
              <div className={styles.total}>
                共
                {
                  totalCount
                }
                项
              </div>
              <div className={styles.pageNum}>
                第
                {current}
                /
                {totalPageCount}
                页
              </div>
            </>
          )
        }
      </div>
      {
        totalPageCount != 0 && (
          <div className={styles.pagination}>
            <Pagination
              current={current}
              onChange={onPageChange}
              onShowSizeChange={onShowSizeChange}
              pageSize={pageSize}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              showQuickJumper={showQuickJumper}
              showSizeChanger={false}
              total={totalCount}
            />
          </div>
        )
      }
    </div>
  );
});
