import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { api } from '../../../utils';
import type { User } from '../navigation/interface';

export class RightSiderStore {
  constructor({ parent }) {
    this.parent = parent;
    this.init();
  }

  @observable public parent;

  @observable public visible = false;

  @observable public waitNum = 0;

  @observable public userInfo: User;

  @action public init = (): void => {
    const isGoodsDetailPage = window.location.pathname === '/egenie-ts-vogue/goodsDetail/index';
    if (isGoodsDetailPage) {
      const firstIn = window.localStorage.getItem('xkDialogNotice');
      if (!firstIn) {
        this.visible = true;
        window.localStorage.setItem('xkDialogNotice', 'true');
      }
    }
  };

  @action public toggleModal = (visible: boolean): void => {
    this.visible = visible;
  };

  @action public goPage = async(jumpTo: string): Promise<void> => {
    const userInfo: User = await request<User>({ url: api.user });
    if (userInfo.tenantType?.includes('100001')) {
      const jumpParam = jumpTo && jumpTo !== 'backstage' ? jumpTo : '';
      window.open(`${api.switchToErpHome}&jumpTo=${jumpParam}`);
    } else {
      window.open(`/egenie-erp-home/shjHome?jumpTo=${jumpTo}`);
    }
    this.visible = false;
  };

  @action public getMyStyleWaitNum = async(): Promise<void> => {
    const res: BaseData<{ waitNum: number; }> = await request({
      url: api.getMyStyleWaitNum,
      method: 'post',
    });
    this.waitNum = res?.data?.waitNum || 0;
  };
}
