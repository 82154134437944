import { Button, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { formatDecimal } from '../../../../utils';
import styles from './index.less';

const GoodsSize: React.FC<{
  sizeList: Array<{
    color: string;
    size: string;
    goodsSkuId: number;
    activityPrice: number;
    discountPrice: number;
    salePrice: number;
    price: number;
    number: number;
    stockNum: number;
    url: string;
  }>;
  totalNum: number;
  totalPrice: number | string;
  onSkuValueChange: (value: number, color: string, goodsSkuId: number, type: 'minus' | 'plus' | 'change') => void;
  propsName: string;
}> = observer((props) => {
  const {
    sizeList = [],
    onSkuValueChange,
    totalPrice,
    totalNum,
    propsName = '尺码',
  } = props;
  return (
    <>
      <div className={styles.goodsSize}>
        <label className={styles.sizeLabel}>
          {propsName}
        </label>
        <div className={styles.sizeListContainer}>
          <div className={styles.sizeList}>
            {sizeList?.map((el, index) => {
              return (
                <div
                  className={`${styles.sizeItem} ${(sizeList.length >= 4 && index === sizeList.length - 1) ? '' : styles.sizeItemBorder}`}
                  key={el.goodsSkuId}
                >
                  {el.size ? (
                    <div className={styles.size}>
                      {el.size}
                    </div>
                  ) : (
                    <div className={styles.colorImgWrap}>
                      <img
                        className={styles.colorSmallImg}
                        src={el.url}
                      />
                      <div className={styles.size}>
                        {el.color}
                      </div>
                    </div>
                  )}
                  <div className={styles.price}>
                    {`¥${formatDecimal(el.price)}`}
                  </div>
                  <div className={styles.numberInput}>
                    <NumInput
                      color={el.color}
                      goodsSkuId={el.goodsSkuId}
                      onInputNumberChange={onSkuValueChange}
                      value={el.number}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.subtotal}>
        <span className={styles.totalPrice}>
          {`小计¥${totalPrice || 0}`}
        </span>
        <span>
          {`，共计${totalNum || 0}件`}
        </span>
      </div>
    </>
  );
});

const NumInput: React.FC<{
  value: number;
  color: string;
  goodsSkuId: number;
  onInputNumberChange?: (value: number, color: string, goodsSkuId: number, type: 'minus' | 'plus' | 'change') => void;
}> = observer((props) => {
  const {
    onInputNumberChange,
    color = '',
    goodsSkuId,
    value,
  } = props;

  return (
    <div className={styles.customNumWrap}>
      <Button
        disabled={!value}
        onClick={() => {
          onInputNumberChange(value - 1, color, goodsSkuId, 'minus');
        }}
        size="small"
      >
        -
      </Button>
      <InputNumber
        className={styles.inputNumber}
        min={0}
        onBlur={(event) => onInputNumberChange(Number(event.target?.value), color, goodsSkuId, 'change')}
        precision={0}
        size="small"
        style={{ width: 60 }}
        value={value}
      />
      <Button
        onClick={() => {
          onInputNumberChange(value + 1, color, goodsSkuId, 'plus');
        }}
        size="small"
      >
        +
      </Button>
    </div>
  );
});

export { GoodsSize };
