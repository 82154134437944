import { Col, Row } from 'antd';
import { nanoid } from 'nanoid';
import React from 'react';
import { NoLackTag } from '../../../components';
import { PUBLIC_IMAGE_URL, replacePath } from '../../../../utils';
import { ColorMap } from '../../store';
import type { MallShopSimpleVo } from '../../types';
import styles from './index.less';

export const VendorInfo: React.FC<{
  mallShopSimpleVo: MallShopSimpleVo;
}> = (props) => {
  const {
    vendorName,
    vendorId,
    qualityLabelList,
    vendorLogo,
    unionFlag,
  } = props.mallShopSimpleVo;
  const jumpToVendorDetail = () => {
    window.open(replacePath(`/egenie-ts-vogue/stallDetail/index?vendorId=${vendorId}`));
  };
  return (
    <div
      className={styles.vendorInfo}
      onClick={jumpToVendorDetail}
    >
      <div className={styles.container}>
        <header
          className={styles.header}
          style={{
            background: `no-repeat center center url(${`${PUBLIC_IMAGE_URL}vendorBackground.png`})`,
            backgroundSize: 'cover',
          }}
        >
          <img
            className={styles.vendorLogo}
            src={vendorLogo ? vendorLogo : `${PUBLIC_IMAGE_URL}vendorIcon.png`}
          />
          <span className={styles.vendorName}>
            {vendorName}
          </span>
        </header>
        <div className={styles.content}>
          <div className={styles.labelContent}>
            <Row gutter={[
              4,
              4,
            ]}
            >
              {qualityLabelList?.map((el, index) => {
                return (
                  <Col key={nanoid()}>
                    <div
                      className={styles.label}
                      style={{ backgroundColor: ColorMap[`${index % 5}`] }}
                    >
                      {el}
                    </div>
                  </Col>
                );
              })}
            </Row>
            {unionFlag ? <NoLackTag/> : null}
          </div>
          <div>
            <span
              className={styles.enterVendor}
            >
              进店
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
