export const TYPES = [
  {
    label: '全部',
    key: '',
  },
  {
    label: '订单',
    key: '1',
  },
  {
    label: '商品',
    key: '2',
  },
];

export const MESSAGE_TYPE = {
  '5': {
    path: '/egenie-ts-vogue/myOrderDetail/index',
    pageName: 'myOrderDetail',
    id: 'mallSaleOrderId',
    metaId: 'saleOrderId',
  },
  '6': {
    path: '/egenie-ts-vogue/myOrderDetail/index',
    pageName: 'myOrderDetail',
    id: 'mallSaleOrderId',
    metaId: 'saleOrderId',
  },
  '8': {
    path: '/egenie-ts-vogue/myOrderDetail/index',
    pageName: 'myOrderDetail',
    id: 'mallSaleOrderId',
    metaId: 'saleOrderId',
  },
  '7': {
    path: '/egenie-ts-vogue/afterSaleOrderDetail/index',
    pageName: 'afterSaleOrderDetail',
    id: 'mallReturnOrderId',
    metaId: 'returnOrderId',
  },
  '9': {
    path: '/egenie-ts-vogue/goodsDetail/index',
    pageName: 'goodsDetail',
    id: 'goodsId',
    metaId: 'goodsId',
  },
  '10': {
    path: '/egenie-ts-vogue/goodsDetail/index',
    pageName: 'goodsDetail',
    id: 'goodsId',
    metaId: 'goodsId',
  },
};
