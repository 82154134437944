import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import { Step } from './constant';
import styles from './index.less';

export const GuideModal = observer((props) => {
  const {
    visible,
    toggleModal,
    goPage,
  } = props.store;
  return (
    <Modal
      className={styles.noticeDialog}
      footer={[
        <Button
          className={styles.okbtn}
          key={nanoid()}
          onClick={() => toggleModal(false)}
          type="primary"
        >
          我已了解
        </Button>,
      ]}
      maskClosable={false}
      onCancel={() => toggleModal(false)}
      open={visible}
      title="新手引导"
      width={800}
    >
      <p className={styles.tip}>
        <ExclamationCircleOutlined className={styles.tipIcon}/>
        按照以下流程操作，即可完成订单一件代发。
        <span
          className={styles.toDetail}
          onClick={() => window.open('https://www.yuque.com/egenie/cpoc39/zgs5gu')}
        >
          查看详细操作说明
        </span>
      </p>
      <div className={styles.stepContainer}>
        {
          Step(goPage)
            .map((item, index) => (
              <div
                className={styles.singleStep}
                key={nanoid()}
              >
                <p className={styles.stepTitle}>
                  <span className={styles.stepNum}>
                    {index + 1}
                    .
                  </span>
                  <div className={styles.stepNameContainer}>
                    <span className={styles.stepName}>
                      {item.name}
                    </span>
                    {
                      index < 3 && <span className={styles.line}/>
                    }
                  </div>
                </p>
                <p
                  className={styles.toText}
                  onClick={() => item.toUrl()}
                >
                  {item.toText}
                </p>
                <img
                  alt=""
                  className={styles.stepImg}
                  src={item.img}
                />
              </div>
            ))
        }
      </div>
    </Modal>
  );
});
