import { api } from '../../../../utils';

export const Step = (goPage) => {
  return [
    {
      name: '店铺授权',
      toText: '去授权>',
      toUrl: () => goPage('shopManage'),
      img: `${api.oss}/images/toAuthorization.png`,
    },
    {
      name: '衫海精首页选款',
      toText: '去选款>',
      toUrl: () => window.open('/egenie-ts-vogue/homePage/index'),
      img: `${api.oss}/images/toXk.png`,
    },
    {
      name: '代发下单',
      toText: '我的订单>',
      toUrl: () => goPage('myOrder'),
      img: `${api.oss}/images/toMyOrder.png`,
    },
    {
      name: '无忧售后',
      toText: '我的售后单>',
      toUrl: () => goPage('afterSaleOrder'),
      img: `${api.oss}/images/toAfterSale.png`,
    },
  ];
};
