import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

const OperationBtns: React.FC<{
  onClickDownLoad: () => void;
  onClickAddToCart: () => void;
  onClickBuyNow: () => void;
  onClickLaunch: () => void;
  onClickAddStyle: () => void;
  gotoMyStyle?: () => void; // 跳转到我的款式页面
  lunchLoading: boolean;
  addStyleLoading: boolean;
  downloadLoading: boolean;
  addCartLoading: boolean;
  doesAddStyle: boolean;
  status: number;
}> = observer((props) => {
  const { onClickDownLoad, onClickAddToCart, onClickBuyNow, onClickLaunch, onClickAddStyle, gotoMyStyle,
    doesAddStyle, downloadLoading, lunchLoading, addStyleLoading, addCartLoading, status } = props;

  return (
    <div className={styles.operationBtns}>
      <Row gutter={[
        8,
        0,
      ]}
      >
        <Col>
          <ButtonCustom
            loading={lunchLoading}
            onClick={onClickLaunch}
            status={status}
            title="一键铺货"
            type="solid"
          />
        </Col>
        <Col>
          {
            doesAddStyle ? (
              <ButtonCustom
                onClick={gotoMyStyle}
                status={status}
                title="已加入我的款式"
                type="hollow"

              />
            ) : (
              <ButtonCustom
                loading={addStyleLoading}
                onClick={onClickAddStyle}
                status={status}
                title="加入我的款式"
                type="hollow"

              />
            )
          }
        </Col>
        <Col>
          <ButtonCustom
            loading={downloadLoading}
            onClick={onClickDownLoad}
            status={status}
            title="下载素材"
            type="hollow"

          />
        </Col>
        <Col>
          <ButtonCustom
            loading={addCartLoading}
            onClick={onClickAddToCart}
            status={status}
            title="加入进货车"
            type="hollow"

          />
        </Col>
        <Col>
          <ButtonCustom
            bgColor="linear-gradient(to right, #FFAE3A, #FF9A12)"
            onClick={onClickBuyNow}
            status={status}
            title="立即订购"
            type="solid"

          />
        </Col>
      </Row>
    </div>
  );
});

/**
 * 自定义button
 * @param type solid:实底;hollow: 空白底
 * @param bgColor 实底背景颜色,不传默认主题色
 * @param title 标题
 * @param loadingText 加载中文案
 * @param loading 加载中状态
 * @param onClick 点击事件
 * @returns
 */
export const ButtonCustom: React.FC<{
  type: 'solid' | 'hollow';
  bgColor?: string;
  title: string;
  loadingText?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick: () => void;
  status;
}> = (props) => {
  const { type, title, onClick, loadingText, loading, bgColor, status } = props;
  return (
    <div
      className={`${styles.button} ${type === 'solid' ? `${styles.solidButton}` : `${styles.hollowButton}`} ${status === 3 ? '' : styles.disabledBtn}`}
      onClick={() => (!loading && status === 3 && onClick())}
      style={bgColor ? { background: bgColor } : {}}
    >
      {title}
      {
        loading && (
          <div
            className={`${styles.loading} ${type === 'solid' ? styles.solidLoading : ''}`}
            style={bgColor ? { background: bgColor } : {}}
          >
            <LoadingOutlined spin/>
            <span className={styles.loadingText}>
              {loadingText || '加载中'}
            </span>
          </div>
        )
      }
    </div>
  );
};

export { OperationBtns };
