import { CloseOutlined } from '@ant-design/icons';
import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import { PUBLIC_IMAGE_URL } from '../../../../utils';
import { Process } from '../operation/process';
import styles from './index.less';

/**
 * @param returnable 是否可退货0: 不支持，1：供应商支持退货，2：无忧退货
 * @param returnRate 供应商支持退货的比例
 * @param returnPeriod 供应商支持退货周期
 * @callback onClick 点击的回调
 * @returns
 */
export const AfterSale: React.FC<Partial<{
  returnable: number;
  returnRate: number;
  returnPeriod: number;
  deliverWarehouseName: string;
  onClick: () => void;
}>> = (props) => {
  const {
    returnable,
    returnRate,
    deliverWarehouseName,
    onClick,
  } = props;
  const tips = {
    '0': '不支持退货',
    '1': `15天可退${returnRate}%`,
    '2': '供应商不支持退货，可购买无忧退货权益包',
  };
  let timer = null;

  const [
    tipVisible,
    setTipVisible,
  ] = useState(false);

  const closePopover = () => {
    setTipVisible(false);
  };

  useEffect(() => {
    document.addEventListener('click', closePopover);
    return () => {
      document.removeEventListener('click', closePopover);
    };
  }, []);

  return (
    <div className={styles.afterSaleContainer}>
      <div
        className={styles.deliverWmsName}
        style={{ marginBottom: 8 }}
      >
        <div>
          发货：
          {deliverWarehouseName}
        </div>
        <Process/>
      </div>
      <div
        className={styles.afterSale}
        onMouseOut={() => {
          clearTimeout(timer);
        }}
        onMouseOver={() => {
          timer = setTimeout(() => {
            setTipVisible(true);
          }, 200);
        }}
      >
        <div className={styles.container}>
          <img
            height={20}
            src={`${PUBLIC_IMAGE_URL}assure.png`}
            width={20}
          />
          <label>
            售后保障：
          </label>
          <div>
            {tips[returnable]}
          </div>
        </div>
        <img
          height={12}
          src={`${PUBLIC_IMAGE_URL}rightArrowRed.png`}
          width={12}
        />

      </div>
      <DetailTips
        returnRate={returnRate}
        returnable={returnable}
        setTipVisible={setTipVisible}
        visible={tipVisible}
      />
    </div>
  );
};

const DetailTips: React.FC<{
  returnable: number;
  returnRate: number;
  visible: boolean;
  setTipVisible: Dispatch<SetStateAction<boolean>>;
}> = (props) => {
  const {
    returnable,
    returnRate,
    visible,
    setTipVisible,
  } = props;
  const detailTips = {
    '0': '非质量问题不支持退换货，因质量问题申请退货需经平台审核通过后方可退货', // 不可退货
    '1': `本商品支持退货，确认收货后15天内可退${returnRate}%`, // 可部分退货
    '2': '本商品支持无忧退货，下单时付费购买无忧退货服务，确认收货后15天内可无理由退货', // 无忧退货
  };
  return (
    <div
      className={styles.tipsPopover}
      onMouseLeave={() => setTipVisible(false)}
      style={{ display: `${visible ? 'block' : 'none'}` }}
    >
      <header className={styles.header}>
        <span>
          售后保障
        </span>
        <span
          className={styles.closeIcon}
        >
          <CloseOutlined/>
        </span>
      </header>
      <div className={styles.tipsContent}>
        {detailTips[returnable]}
      </div>
    </div>
  );
};
