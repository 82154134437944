// import shop_icon_10 from '../../assets/images/platform/shop_icon_10.png';
import shopIcon0100 from '../assets/images/platform/shop_icon_-100@3x.png';
import shopIcon10002 from '../assets/images/platform/shop_icon_10002@3x.png';
import shopIcon11 from '../assets/images/platform/shop_icon_11@3x.png';
import shopIcon12 from '../assets/images/platform/shop_icon_12@3x.png';

// import shop_icon_13 from '../../assets/images/platform/shop_icon_13.png';@3x
import shopIcon14 from '../assets/images/platform/shop_icon_14@3x.png';
import shopIcon15 from '../assets/images/platform/shop_icon_15@3x.png';
import shopIcon1688 from '../assets/images/platform/shop_icon_1688@3x.png';
import shopIcon16 from '../assets/images/platform/shop_icon_16@3x.png';
import shopIcon17 from '../assets/images/platform/shop_icon_17@3x.png';
import shopIcon18 from '../assets/images/platform/shop_icon_18@3x.png';
import shopIcon19 from '../assets/images/platform/shop_icon_19@3x.png';

// import shop_icon_20 from '../../assets/images/platform/shop_icon_20.png';
import shopIcon21 from '../assets/images/platform/shop_icon_21@3x.png';
import shopIcon22 from '../assets/images/platform/shop_icon_22@3x.png';
import shopIcon23 from '../assets/images/platform/shop_icon_23@3x.png';
import shopIcon24 from '../assets/images/platform/shop_icon_24@3x.png';
import shopIcon25 from '../assets/images/platform/shop_icon_25@3x.png';
import shopIcon26 from '../assets/images/platform/shop_icon_26@3x.png';
import shopIcon2 from '../assets/images/platform/shop_icon_2@3x.png';
import shopIcon37 from '../assets/images/platform/shop_icon_37@3x.png';

// import shop_icon_3 from '../../assets/images/platform/shop_icon_3.png';
import shopIcon4 from '../assets/images/platform/shop_icon_4@3x.png';
import shopIcon5 from '../assets/images/platform/shop_icon_5@3x.png';
import shopIcon6 from '../assets/images/platform/shop_icon_6@3x.png';
import shopIcon7 from '../assets/images/platform/shop_icon_7@3x.png';
import shopIcon8 from '../assets/images/platform/shop_icon_8@3x.png';
import shopIcon9 from '../assets/images/platform/shop_icon_9@3x.png';
import shopIconTaobao from '../assets/images/shop_icon_taobao.png';
import douyinProviderIcon from '../assets/images/platform/douyin_provider_icon.png';
import shopIcon31 from '../assets/images/platform/shop_icon_31@3x.png';

// shopIcon后的数字代表返回的平台类型
const getPlatformTypeIcon = (type: number) => {
  let imgUrl;
  switch (type) {
    case -100:
      imgUrl = shopIcon0100;
      break;
    case 1:
      imgUrl = shopIconTaobao;
      break;
    case 2:
      imgUrl = shopIcon2;
      break;
    case 4:
      imgUrl = shopIcon4;
      break;
    case 5:
      imgUrl = shopIcon5;
      break;
    case 6:
      imgUrl = shopIcon6;
      break;
    case 7:
      imgUrl = shopIcon7;
      break;
    case 8:
      imgUrl = shopIcon8;
      break;
    case 9:
      imgUrl = shopIcon9;
      break;
    case 11:
      imgUrl = shopIcon11;
      break;
    case 12:
      imgUrl = shopIcon12;
      break;
    case 14:
      imgUrl = shopIcon14;
      break;
    case 15:
      imgUrl = shopIcon15;
      break;
    case 16:
      imgUrl = shopIcon16;
      break;
    case 17:
      imgUrl = shopIcon17;
      break;
    case 18:
      imgUrl = shopIcon18;
      break;
    case 19:
      imgUrl = shopIcon19;
      break;
    case 21:
      imgUrl = shopIcon21;
      break;
    case 22:
      imgUrl = shopIcon22;
      break;
    case 23:
      imgUrl = shopIcon23;
      break;
    case 24:
      imgUrl = shopIcon24;
      break;
    case 25:
      imgUrl = shopIcon25;
      break;
    case 26:
      imgUrl = shopIcon26;
      break;
    case 37:
      imgUrl = shopIcon37;
      break;
    case 1688:
      imgUrl = shopIcon1688;
      break;
    case 10002:
      imgUrl = shopIcon10002;
      break;
    case 32:
      imgUrl = douyinProviderIcon;
      break;
    case 31:
      imgUrl = shopIcon31;
      break;
  }

  // console.log(imgUrl);
  return imgUrl;
};

const SHELVES_PLATFORM = [
  {
    shopPlatformType: -100, // 天猫
    url: '/egenie-ts-vogue/shelvesNew/index',
  },
  {
    shopPlatformType: 1, // 淘宝
    url: '/egenie-ts-vogue/shelvesNew/index',
  },
  {
    shopPlatformType: 21, // 抖音
    url: '/egenie-ts-vogue/shelvesDouyin/index',
  },
];
const SellingPointTag: {[key in number]: { color: string; text: string; backgroundColor: string; }} = {
  '100': {
    color: '#9A08DD',
    text: '尾货',
    backgroundColor: '#9A08DD14',
  },
  '101': {
    color: '#EF8300',
    text: '福利款',
    backgroundColor: '#EF830014',
  },
  '102': {
    color: '#FB071F',
    text: '爆款',
    backgroundColor: '#FB071F14',
  },
  '103': {
    color: '#00A328',
    text: '原创款',
    backgroundColor: '#00A32814',
  },
  '104': {
    color: '#1C53F6',
    text: '现货',
    backgroundColor: '#1C53F614',
  },
  '105': {
    color: '#1C53F6',
    text: '下订单',
    backgroundColor: '#1C53F614',
  },
  '106': {
    color: '#FB4A07',
    text: '预售',
    backgroundColor: '#FB4A0714',
  },
};
export { getPlatformTypeIcon, SHELVES_PLATFORM, SellingPointTag };
