import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { BaseData, IObj, PaginationData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { api, checkLoginStatus } from '../../../utils';
import PaginationStore from '../pagination/store';
import { MESSAGE_TYPE } from './constant';
import type { IMessageList, IQueryConditions, User } from './interface';
import NotificationDrawerStore from '../notificationDrawer/store';

export class Store {
  @observable public visible = false;

  @observable public loading = false;

  @observable public messageList: IMessageList[] = [];

  @observable public currentType = '';

  @observable public totalCount = 0;

  @observable public totalPageCount = 0;

  @observable public unMessageNum = 0;

  @observable public isRead = false;

  @observable public notificationCenterStore = new NotificationDrawerStore({ parent: this });

  @observable public queryConditions: IQueryConditions = {
    page: 1,
    pageSize: 20,
    sidx: '',
    sord: '',
    type: undefined,
    unReadMessage: false,
  };

  @observable public paginationStore = new PaginationStore({ parent: this });

  @observable public userInfo: User;

  @action public init = (): void => {
    this.getMessageList();
    this.getUserInfo();
  };

  @action public getMessageList = async(param?: Partial<IQueryConditions>): Promise<void> => {
    const params = {
      ...this.queryConditions,
      ...param,
    };
    Object.assign(this.queryConditions, { ...param });
    try {
      this.loading = true;

      const res: PaginationData<IMessageList> = await request({
        url: api.getAllMessage,
        method: 'post',
        data: params,
      });
      this.loading = false;
      this.messageList = res.data.list;
      this.totalCount = res.data.totalCount;
      this.totalPageCount = res.data.totalPageCount;
    } catch (error) {
      this.loading = false;
    }
  };

  @action public toogleDrawer = async(visible: boolean): Promise<void> => {
    const loginStatus = await checkLoginStatus();
    if (!loginStatus) {
      window.location.assign('/egenie-ts-vogue/login');
      return;
    }
    this.notificationCenterStore.show();

    // if (visible) {
    //   this.init();
    // } else {
    //   this.queryConditions = {
    //     page: 1,
    //     pageSize: 20,
    //     sidx: '',
    //     sord: '',
    //     type: undefined,
    //     unReadMessage: false,
    //   };
    //   this.isRead = false;
    //   this.paginationStore.resetPage();
    //   this.changeType('');
    // }
    // this.visible = visible;
  };

  @action public changeType = (type: string): void => {
    this.currentType = type;
    this.getMessageList({
      page: 1,
      pageSize: 20,
      type: type ? Number(type) : undefined,
    });
  };

  @action public getUnReadMessage = async() => {
    const res: BaseData<{
      total: number;
    }> = await request({
      url: api.getNotReadMessageStatistics,
      data: { systemType: 1000100 },
      method: 'POST',
    });
    this.unMessageNum = res.data.total;
  };

  @action public getUserInfo = () => {
    request<User>({ url: api.user })
      .then((res) => {
        this.userInfo = res;
      });
  };

  @action public onClickMessage = (id: number, status: number, messageType: number, metaData: IObj): void => {
    if (status !== 1) {
      this.hasReadMessage(id);
      const item = this.messageList.find((el) => el.id === id);
      if (item) {
        item.status = 1;
      }
    }

    const jumpPageInfo = MESSAGE_TYPE[messageType];
    if (jumpPageInfo) {
      let url = '';
      if (this.userInfo.tenantType?.includes('100001')) {
        url = `${api.switchToErpHome}&jumpTo=`;
      } else {
        url = '/egenie-erp-home/shjHome?jumpTo=';
      }
      const id = metaData?.[jumpPageInfo.metaId];
      if (messageType > 8 && messageType < 11) {
        if (id) {
          window.open(`${jumpPageInfo.path}?goodsId=${id}`);
          console.log('url...1', `${jumpPageInfo.path}?goodsId=${id}`);
        }
      } else {
        window.open(`${url}${jumpPageInfo.pageName}&${jumpPageInfo.id}=${id}`);
        console.log('url...2', `${url}${jumpPageInfo.pageName}&${jumpPageInfo.id}=${id}`);
      }
    }
  };

  @action public hasReadMessage = async(id: number): Promise<void> => {
    await request({
      url: api.onSelectedMessage,
      params: { id },
    });
    this.paginationStore.resetPage();
    this.getMessageList({
      page: 1,
      pageSize: 20,
    });
    this.getUnReadMessage();
  };

  @action public changeCheckBox = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    this.isRead = checked;
    const params = {
      unReadMessage: checked,
      page: 1,
      pageSize: 20,
    };
    this.paginationStore.resetPage();
    this.getMessageList(params);
  };

  @action public setAllRead = () => {
    this.hasReadMessage(null);
  };

  @action public onPageChange = (page: number, pageSize: number): void => {
    this.getMessageList({
      page,
      pageSize,
    });
  };

  @action public onShowSizeChange = (page = 1, pageSize: number): void => {
    this.getMessageList({
      page,
      pageSize,
    });
  };
}
