import { Col, Row, Skeleton } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { PUBLIC_IMAGE_URL } from '../../../../utils';
import { PictureMagnifier } from '../index';
import styles from './index.less';

interface Props {
  videoUrl?: string; // 主图url
  picUrl: string; // 主图
  mainPics: string[]; // 主图数组
  setUrl: (url: string, type: 'picture' | 'video') => void;
  status: number;
}

// 商品/款式基本信息的主图列表
const GoodsMainPics = observer((props: Props): JSX.Element => {
  const {
    picUrl,
    mainPics = [],
    videoUrl,
    setUrl,
    status,
  } = props;
  let sourceList = [...mainPics];
  if (videoUrl) {
    sourceList = [
      videoUrl,
      ...mainPics,
    ];
  }
  const firstRender = useRef<boolean>(true); // 是否第一次render
  const [ // 是否展示视频
    isVideo,
    setIsVideo,
  ] = useState(false);

  const [
    mainPicUrl,
    setMainPicUrl,
  ] = useState(videoUrl || mainPics[0] || picUrl);

  const setPicUrl = (url: string): void => {
    setMainPicUrl(url);
    if (url === videoUrl) {
      setIsVideo(true);
    } else {
      setIsVideo(false);
    }
  };

  // 颜色改变，修改展示的主图
  useEffect(() => {
    if (!firstRender.current) {
      setMainPicUrl(picUrl);
      setIsVideo(false);
    }
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, [picUrl]);

  // 判断是否有视频
  useEffect(() => {
    if (videoUrl) {
      setMainPicUrl(videoUrl);
      setIsVideo(true);
    } else {
      setMainPicUrl(picUrl || mainPics[0]);
    }
  }, [videoUrl]);
  return (
    <div className={styles.goodsMainPics}>
      <div className={styles.picAndVideo}>
        <div className={styles.pictureContainer}>
          {!isVideo && (
            mainPicUrl
              ? (
                <PictureMagnifier
                  imgUrl={mainPicUrl}
                  status={status}
                />
              )
              : <Skeleton.Image className={styles.mainPic}/>
          )}
        </div>
        <div
          className={styles.videoContainer}
          style={{ display: isVideo ? 'block' : 'none' }}
        >
          <video
            autoPlay
            controls
            height={410}
            muted
            src={videoUrl}
            width={410}
          />
        </div>
      </div>
      <div
        className={styles.picList}
        style={{ overflowY: 'auto' }}
      >
        <Row gutter={[
          4,
          4,
        ]}
        >
          {sourceList && sourceList.map((el: string, index: number) => {
            // 图片激活样式
            if (el === mainPicUrl && el !== videoUrl) {
              return (
                <Col>
                  <div
                    className={`${styles.activeMainPicItemWrapper} ${styles.mainPicItem}`}
                    key={el}
                  >
                    <div className={styles.smallPicWrap}>
                      <img
                        className={`${styles.activeSmallPic} `}
                        src={el}
                      />
                    </div>
                    <div
                      className={`${styles.activeMainPicItem} ${styles.mainPicItem}`}
                      key={el}
                    />
                  </div>
                </Col>
              );
            }

            // 视频激活样式
            if (el === mainPicUrl && el === videoUrl) {
              return (
                <Col>
                  <div
                    className={`${styles.activeMainPicItemWrapper} ${styles.mainPicItem}`}
                  >
                    <video
                      className={styles.activeSmallPic}
                      height={60}
                      preload="metadata"
                      src={`${videoUrl}#t=0.1`}
                      width={60}
                    />
                    <div
                      className={`${styles.activeMainPicItem} ${styles.mainPicItem}`}
                    />
                    <img
                      className={styles.videoIcon}
                      height={26}
                      src={`${PUBLIC_IMAGE_URL}video.png`}
                      width={26}
                    />
                  </div>
                </Col>
              );
            }

            // 视频正常展示
            if (el === videoUrl) {
              return (
                <Col>
                  <div className={`${styles.smallPic} ${styles.mainPicItem}`}>
                    <video
                      height={62}
                      onMouseEnter={() => setPicUrl(el)}
                      src={videoUrl}
                      width={62}
                    />
                    <img
                      className={styles.videoIcon}
                      src={`${PUBLIC_IMAGE_URL}video.png`}
                    />
                  </div>
                </Col>
              );
            }
            return (
              <Col key={el}>
                <div className={styles.smallPicWrap}>
                  <img
                    className={styles.smallPic}
                    onMouseEnter={() => {
                      setPicUrl(el);
                      setUrl(el, el !== videoUrl ? 'picture' : 'video');
                    }}
                    src={el}
                  />
                </div>
              </Col>
            );
          }
          )}
        </Row>
      </div>
    </div>
  );
});

export { GoodsMainPics };
