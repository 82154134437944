import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../../utils';
import styles from './index.less';

const PROCESS_LIST = [
  {
    img: 'puhuored.png',
    text: '铺货电商平台',
  },
  {
    img: 'daifa.png',
    text: '一件代发',
  },
  {
    img: 'fahuo.png',
    text: '网仓发货',
  },
  {
    img: 'shouhou.png',
    text: '网仓售后',
  },
];

const PerformanceProcess = () => {
  return (
    <div className={styles.processWrapper}>
      <div className={styles.title}>
        <img
          className={styles.titleImg}
          src={`${PUBLIC_IMAGE_URL}processTitle.png`}
        />
      </div>
      <div className={styles.content}>
        {
          PROCESS_LIST.map((item, index) => (
            <div key={item.text}>
              <div>
                <img
                  className={styles.processImg}
                  src={`${PUBLIC_IMAGE_URL}${item.img}`}
                />
                <span>
                  {item.text}
                </span>
              </div>
              {index < (PROCESS_LIST.length - 1) && (
                <img
                  className={styles.arrowImg}
                  src={`${PUBLIC_IMAGE_URL}bottomArrowRed.png`}
                />
              )}
            </div>
          ))
        }
      </div>
    </div>
  );
};

export { PerformanceProcess };
