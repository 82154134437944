import { message } from 'antd';
import type { BaseData, User } from 'egenie-common';
import { request, toFixed } from 'egenie-common';
import type { CSSProperties } from 'react';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import noPic from '../../../assets/images/noPic.png';
import { api, checkLoginStatus, isDistributor, LoginModal, PUBLIC_IMAGE_URL, replacePath, useCheckLogin } from '../../../utils';
import { LaunchModal, LaunchStore } from '../index';
import styles from './index.less';

/**
 * @name 商品卡片
 * @param style 行内样式
 * @param className 类名
 * @param mainPic 主图
 * @param goodsId 商品id
 * @param goodsName 商品名称
 * @param marketLabelName 市场名称
 * @param videoFlag 视频标记，0无视频、1有视频
 * @param isOpen 私密款标记，1公开、2私密
 * @param picGroupNum 组图数量
 * @param returnable 是否可退0: 不可退、1可退部分、2无忧退货
 * @param returnRate 可退比例
 * @param wholePrice 进货价/成本价
 * @param discountPrice 折扣价/专享价
 * @param activityPrice 活动价
 * @param dynamicId 动态id
 * @param sellingPointTag 卖点标签
 * @param doesAddStyle 是否加入我的款式
 * @param virtualSaleNum 销量(假数据)
 * @param virtualSelectNum 铺货数量(假数据)
 * @callback onClick 点击的回调
 * @returns
 */
export const ProductCard: React.FC<Partial<{
  style: CSSProperties;
  className: string;
  mainPic: string;
  goodsName: string;
  goodsId: number;
  marketLabelName: string;
  isOpen: 1 | 2;
  returnable: 0 | 1 | 2;
  returnRate: number;
  wholePrice: number;
  discountPrice: number;
  activityPrice: number;
  videoFlag: 0 | 1;
  picGroupNum: number;
  dynamicId: string;
  onClick: () => void;
  trackingInfo: object;
  overflow?: boolean;
  sellingPointTag: string;
  doesAddStyle: boolean;
  virtualSaleNum: number;
  virtualSelectNum: number;
  callback?: () => void;
  sellerOuterNo: string;// 货号
  liveGoodsVo: any;
}>> = (props) => {
  const {
    className,
    style,
    mainPic,
    goodsName,
    marketLabelName,
    isOpen,
    videoFlag,
    returnable,
    returnRate,
    wholePrice,
    discountPrice,
    activityPrice,
    goodsId,
    onClick,
    picGroupNum,
    dynamicId,
    trackingInfo,
    overflow,
    sellingPointTag,
    doesAddStyle,
    virtualSaleNum,
    virtualSelectNum,
    callback,
    sellerOuterNo,
    liveGoodsVo,
  } = props;
  const {
    checkLoginFn,
    visible,
    setVisible,
  } = useCheckLogin();

  const launchStore = new LaunchStore({ parent: undefined });

  const [
    hover,
    setHover,
  ] = useState(false);

  const [
    loading,
    setLoading,
  ] = useState(false);

  const [
    addStyle,
    setAddStyle,
  ] = useState(false);

  useEffect(() => {
    setAddStyle(doesAddStyle);
  }, [doesAddStyle]);

  const onClickCard = () => {
    if (onClick) {
      onClick();
    } else {
      window.open(replacePath(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}&dynamicId=${dynamicId || ''}`));
    }
  };

  // 一键铺货
  const openLaunchModal = async(e): Promise<void> => {
    e.stopPropagation();
    if (isDistributor()) {
      checkLoginFn(() => {
        launchStore?.openModal(goodsId, dynamicId, 'front');
      });
    } else {
      const loginStatus = await checkLoginStatus();
      if (!loginStatus) {
        window.location.assign('/egenie-ts-vogue/login');
        return;
      }
      launchStore?.openModal(goodsId, dynamicId, 'front');
    }
  };

  // 加入我的款式
  const addMyStyle = async(e): Promise<void> => {
    e.stopPropagation();
    if (isDistributor()) {
      checkLoginFn(() => {
        dealAddStyle();
      });
    } else {
      const loginStatus = await checkLoginStatus();
      if (!loginStatus) {
        window.location.assign('/egenie-ts-vogue/login');
      }
      dealAddStyle();
    }
  };
  const dealAddStyle = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    request<BaseData<string>>({
      url: '/api/mall/rest/goods/addStyle',
      method: 'GET',
      params: { goodsId },
    })
      .then((res) => {
        message.success('加入成功');
        setAddStyle(true);
        callback();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 跳转到我的款式
  const gotoMyStyle = async(e): Promise<void> => {
    e.stopPropagation();
    const userInfo: User = await request({ url: api.user });
    if (userInfo.tenantType?.includes('100001')) {
      window.open(`${api.switchToErpHome}&jumpTo=myStyle`); // erp账户跳转到erp首页
    } else {
      window.open('/egenie-erp-home/shjHome?jumpTo=myStyle');
    }
  };

  return (
    <div
      className={`${styles.card} ${className ? className : ''}`}
      data-id={JSON.stringify({
        ...trackingInfo,
        goodsId,
        jump: '/egenie-ts-vogue/goodsDetail/index',
      })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ ...style }}
    >
      <div
        className={styles.topContainer}
        onClick={onClickCard}
      >
        <LazyLoad
          height={200}
          offset={400}
          overflow={overflow}
          style={{ textAlign: 'center' }}
        >
          <img
            className={styles.mainPicture}
            src={mainPic || noPic}
          />
        </LazyLoad>
        <div className={styles.topLabels}>
          {isOpen === 2 && (
            <div className={styles.visible}>
              密
            </div>
          )}
          {Boolean(videoFlag) && (
            <div className={styles.video}>
              <img
                className={styles.videoIcon}
                src={`${PUBLIC_IMAGE_URL}video.png`}
              />
              <span>
                视频
              </span>
            </div>
          )}
        </div>
        {sellingPointTag && (
          <div className={styles.specialTag}>
            {sellingPointTag}
          </div>
        )}
        {hover && (
          <div className={styles.operationBtns}>
            <div
              className={styles.btn}
              onClick={openLaunchModal}
            >
              一键铺货
            </div>
            <div className={styles.splitIcon}/>
            <div
              className={styles.btn}
              onClick={addStyle ? gotoMyStyle : addMyStyle}
            >
              {addStyle && '已'}
              加入我的款式
            </div>
          </div>
        )}
        <div className={styles.bottomLabels}>
          {!hover && picGroupNum && picGroupNum > 1 && (
            <div className={styles.picGroup}>
              {picGroupNum}
              <span>
                套图
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        className={styles.bottomContainer}
        onClick={onClickCard}
      >
        {hover ? (
          <div
            className={styles.saleNum}
          >
            <div>
              <span className={styles.saleNumLabel}>
                销量：
              </span>
              {virtualSaleNum > 100000 ? '99999+' : virtualSaleNum}
              件
            </div>
            <div>
              <span className={styles.saleNumLabel}>
                铺货次数：
              </span>
              {virtualSelectNum}
              次
            </div>
          </div>
        )
          : (
            <>
              <div className={styles.goodsName}>
                {liveGoodsVo && (
                  <span className={styles.tag}>
                    直播款
                  </span>
                )}
                {goodsName}
              </div>
              <div className={styles.sellerOuterNo}>
                货号：
                {sellerOuterNo}
              </div>
              <div className={styles.tagContainer}>
                <Tag
                  marketLabelName={marketLabelName}
                  type="market"
                />
                <Tag
                  returnRate={returnRate}
                  returnable={returnable}
                  type="return"
                />
              </div>
              <div className={styles.price}>
                <PriceLine
                  activePrice={activityPrice}
                  costPrice={wholePrice}
                  discountPrice={discountPrice}
                />
              </div>
            </>
          )}
      </div>
      <LaunchModal store={launchStore}/>
      <LoginModal
        setVisible={setVisible}
        visible={visible}
      />
    </div>
  );
};

/**
 * @name 标签
 * @returns
 */
export const Tag: React.FC<{
  type: 'return' | 'market';
  marketLabelName?: string;
  returnable?: 0 | 1 | 2; // 是否支持退货(0.不支持  1.供应商支持退货 2.支持无忧退货
  returnRate?: number;
}> = (props) => {
  const {
    type,
    marketLabelName,
    returnable,
    returnRate,
  } = props;
  if (type === 'return' && returnable === 0 || type === 'return' && !returnable) {
    return null;
  }

  if (type === 'market' && !marketLabelName) {
    return null;
  }
  const tags = {
    return: {
      title: returnable === 1 ? `15天可退${returnRate || '-'}%` : returnable === 2 ? '无忧退货' : '',
      className: styles.allReturn,
    },
    market: {
      title: marketLabelName,
      className: styles.marketTag,
    },
  };
  return (
    <div className={tags[type]?.className}>
      {tags[type]?.title}
    </div>
  );
};

/**
 * @name 价格展示
 * @param costPrice 进货价/成本价
 * @param discountPrice 折扣价/专享价
 * @param activePrice 活动价
 * @returns
 */
export const PriceLine: React.FC<Partial<{
  costPrice: number;
  discountPrice: number | string;
  activePrice: number;
}>> = (props) => {
  const {
    costPrice,
    discountPrice,
    activePrice,
  } = props;

  // 处理价格
  const handlePrice = (costPrice: number, discountPrice: number | string, activePrice: number): { intPart: string; decimalPart: string; } => {
    let tempPrice = 0;
    let tempDiscountPrice = '';
    if (discountPrice) {
      tempDiscountPrice = `${discountPrice}`.replace(/,/g, '');
    }
    tempPrice = Number(activePrice) || Number(discountPrice) || Number(costPrice);
    if (costPrice && tempDiscountPrice && activePrice) {
      tempPrice = Math.min(Number(costPrice), Number(tempDiscountPrice), Number(activePrice));
    }
    if (tempDiscountPrice && activePrice) {
      tempPrice = Math.min(Number(tempDiscountPrice), Number(activePrice));
    }
    if (typeof tempPrice !== 'number' || !tempPrice) {
      return {
        intPart: '0',
        decimalPart: '00',
      };
    } else {
      const [
        intPart,
        decimalPart,
      ] = toFixed(tempPrice, 2)
        .split('.');
      return {
        intPart,
        decimalPart,
      };
    }
  };
  const {
    intPart,
    decimalPart,
  } = handlePrice(costPrice, discountPrice, activePrice);

  // 价格展示部分
  const pricePart = (
    <div className={styles.showPrice}>
      <span className={styles.priceIcon}>
        ¥
      </span>
      <span className={styles.intPart}>
        {intPart}
        .
      </span>
      <span className={styles.decimalPart}>
        {decimalPart}
      </span>
    </div>
  );

  // 活动价折扣价标签
  const SpecialLabel: React.FC<{ name: string; }> = (props) => {
    const { name } = props;
    return (
      <div className={styles.specialPrice}>
        {name}
      </div>
    );
  };

  // 划线的价格
  const LineThrough: React.FC<{ name: string; price: number | string; }> = (props) => {
    const {
      name,
      price,
    } = props;
    return (
      <div className={styles.lineThrough}>
        <label>
          {name}
        </label>
        <span className={styles.lineThroughPrice}>
          ¥
          {toFixed(price, 2)}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.priceLine}>
      {/* 只有进货价 */}
      {!discountPrice && !activePrice && (
        <span>
          {pricePart}
        </span>
      )}

      {/* 有专享价无活动价  */}
      {Boolean(discountPrice && !activePrice) && (
        <>
          <SpecialLabel name="专享"/>
          {pricePart}
          {costPrice !== discountPrice && (
            <LineThrough
              name=""
              price={costPrice}
            />
          )}
        </>
      )}

      {/* 有活动无专享 */}
      {Boolean(!discountPrice && activePrice) && (
        <>
          <SpecialLabel name="活动"/>
          {pricePart}
          {costPrice !== activePrice && (
            <LineThrough
              name=""
              price={costPrice}
            />
          )}
        </>
      )}

      {/* 有活动有专享 */}
      {Boolean(discountPrice && activePrice) && (
        <>
          <SpecialLabel name="活动"/>
          {pricePart}
          <LineThrough
            name="专享价"
            price={discountPrice}
          />
        </>
      )}
    </div>
  );
};

