import { DownloadOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { toFixed } from 'egenie-common';
import { observer } from 'mobx-react';
import QRCode from 'qrcode.react';
import React from 'react';

import styles from './index.less';
import { isDistributor } from '../../../../utils';

const OperationArea: React.FC<{
  goodsId: number;
  goodsName: string;
  picUrl: string;
  vendorName: string;
  price: number;
  collected: boolean;
  downloadQRcode: () => void;
  collectionOrCancel: () => void;
}> = observer((props) => {
  const {
    goodsId,
    goodsName,
    picUrl,
    vendorName,
    price,
    downloadQRcode,
    collected,
    collectionOrCancel,
  } = props;
  const isDistributorType = isDistributor();
  if (isDistributorType) {
    return null;
  }
  return (
    <div className={styles.operationArea}>
      <div
        className={styles.area}
        onClick={collectionOrCancel}
      >
        {
          !collected ? (
            <>
              <StarOutlined className={styles.collectionIcon}/>
              收藏款式
            </>
          ) : (
            <>
              <StarFilled className={`${styles.collectionIcon} ${styles.collectedIcon}`}/>
              取消收藏
            </>
          )
        }
      </div>
      <Popover
        content={(
          <QRcodeContent
            goodsId={goodsId}
            goodsName={goodsName}
            picUrl={picUrl}
            price={price}
            vendorName={vendorName}
          />
        )}
        getPopupContainer={() => document.getElementById('downloadPopover')}
        placement="bottom"
      >
        <div
          className={styles.area}
          id="downloadPopover"
          onClick={downloadQRcode}
        >
          <DownloadOutlined/>
          <span className={styles.text}>
            下载二维码
          </span>
        </div>
      </Popover>
    </div>
  );
});

const QRcodeContent: React.FC<{
  goodsId: number;
  goodsName: string;
  picUrl: string;
  vendorName: string;
  price: number;
}> = (props) => {
  const {
    goodsId,
    goodsName,
    picUrl,
    vendorName,
    price,
  } = props;
  const qrcodeValue = `${window.location.origin}/wx/shopkeeper/styleDetail?goodsId=${goodsId}`;
  const _price = price;
  const max = toFixed(_price, 2)
    .toString()
    .split('.')[0];
  const min = toFixed(_price, 2)
    .toString()
    .split('.')[1];
  return (
    <div
      className={styles.popoverContent}
      id="qrcodeCanvas"
    >
      <img
        src={picUrl}
        style={{
          width: 200,
          height: 200,
        }}
      />
      <div
        className={styles.goodsName}
        title={goodsName}
      >
        {goodsName}
      </div>
      <div
        className={styles.vendorName}
        title={vendorName}
      >
        {vendorName}
      </div>
      <div className={styles.priceQrcodeWrap}>
        <div className={styles.priceWrap}>
          <span
            className={`${styles.font12} ${styles.mr4}`}
          >
            进货价
          </span>
          <div className={styles.price}>
            <span className={styles.font14}>
              ¥
            </span>
            <span className={styles.font20}>
              {max}
              .
              {min}
            </span>
          </div>
        </div>
        <QRCode
          fgColor="#000000"
          size={72}
          value={qrcodeValue || ''}
        />
      </div>
    </div>
  );
};

export { OperationArea };
