/**
 * 活动倒计时
 * @param countDownTime: { hours, minutes, seconds, lastEndTime, currentActivity }, pcFontColor
 * countDownTime：活动状态和结束时间以及时分秒
 * currentActivity： start（距离开始） ｜ end（距离结束） ｜over（结束）
 * pcFontColor： 返回字体颜色
 */
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

interface ICpintDownProps {
  hours: string;
  minutes: string;
  seconds: string;
  lastEndTime: number;
  currentActivity: string;
}

// 活动结束倒计时
export const CountDownCom = observer((props: { countDownTime: ICpintDownProps; pcFontColor: string[]; }) => {
  const { countDownTime: { hours, minutes, seconds, lastEndTime, currentActivity }, pcFontColor } = props;
  return (
    <div style={{
      height: '20px',
      lineHeight: '20px',
    }}
    >
      {
        (currentActivity === 'start' || (currentActivity === 'end' && (lastEndTime < 24 * 60 * 60 * 1000))) && (
          <div className={styles.countDown}>
            <span>
              距活动
              {currentActivity === 'start' ? '开始' : '结束'}
            </span>

            <div
              className={styles.countdownTime}
              style={{ color: pcFontColor[0] || '' }}
            >
              {hours}
            </div>
            时
            <div
              className={styles.countdownTime}
              style={{ color: pcFontColor[0] || '' }}
            >
              {minutes}
            </div>
            分
            <div
              className={styles.countdownTime}
              style={{ color: pcFontColor[0] || '' }}
            >
              {seconds}
            </div>
            秒
          </div>
        )
      }
      {
        (currentActivity === 'end' && (lastEndTime > 24 * 60 * 60 * 1000)) && (
          <div className={styles.countDown}>
            活动火热进行中…
          </div>
        )
      }
      {
        currentActivity === 'over' && (
          <div className={styles.countDown}>
            活动已结束
          </div>
        )
      }
    </div>
  );
});
