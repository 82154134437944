import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../../utils';
import styles from './index.less';

interface FastTrackProps{
  onClickDownLoad: () => void;// 下载素材
  onClickLaunch: () => void;// 铺货
  onClickAddStyle: () => void;// 添加到我的款式
  gotoMyStyle?: () => void; // 跳转到我的款式页面
  doesAddStyle: boolean;// 是否已加入我的款式
}

// 快速通道
export const FastTrack: React.FC<FastTrackProps> = (props) => {
  const { onClickDownLoad, onClickLaunch, onClickAddStyle, gotoMyStyle, doesAddStyle } = props;
  return (
    <div className={styles.fastTrackWrapper}>
      <div className={styles.header}>
        <img
          className={styles.faskTrackTitle}
          src={`${PUBLIC_IMAGE_URL}fastTrack.png`}
        />
      </div>
      <div className={styles.content}>
        <div
          className={styles.eachButton}
          onClick={onClickLaunch}
        >
          <img
            className={styles.img}
            src={`${PUBLIC_IMAGE_URL}puhuo.png`}
          />
          一键铺货
        </div>
        {
          doesAddStyle ? (
            <div
              className={styles.eachButton}
              onClick={gotoMyStyle}
            >
              <img
                className={styles.img}
                src={`${PUBLIC_IMAGE_URL}alreadyAdd.png`}
              />
              已加入我的款式
            </div>
          ) : (
            <div
              className={styles.eachButton}
              onClick={onClickAddStyle}
            >
              <img
                className={styles.img}
                src={`${PUBLIC_IMAGE_URL}addStyle.png`}
              />
              加入我的款式
            </div>
          )
        }
        <div
          className={styles.eachButton}
          onClick={onClickDownLoad}
        >
          <img
            className={styles.img}
            src={`${PUBLIC_IMAGE_URL}download.png`}
          />
          下载素材
        </div>
      </div>
    </div>
  );
};
