import { InfoCircleFilled, RightOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import Sticky from 'react-stickynode';
import styleDetailBg from '../../assets/images/styleDetailBg.png';
import styleDetailClock from '../../assets/images/styleDetailClock.png';
import { LoginModal, SellingPointTag, useCheckLogin } from '../../utils';
import { ActivePriceComponent, Footer, FooterLogo, LaunchModal, Navigation, RightSider } from '../components';
import { CountDownCom } from '../components/countDownCom';
import { AfterSale,
  FastTrack,
  GoodsAttributes,
  GoodsColor,
  GoodsMainPics,
  GoodsSize,
  OperationArea,
  OperationBtns,
  PerformanceProcess,
  PicSeries,
  Price,
  VendorInfo } from './component';
import { SimilarStyle } from './component/similarStyle';
import { StallNewStyle } from './component/stallNewStyle';
import Tag from './component/tags';
import styles from './index.less';
import DownloadModal from './modal/download';
import Store from './store';
import Wechat from './component/wechat';

const store = new Store();

const GoodsDetail: React.FC<unknown> = observer(() => {
  const {
    goodsId,
    pageLoading,
    launchStore,
    downloadStore,
    openDownload,
    activityPrice,
    minWholePrice,
    discountPrice,
    downloadQRcode,
    collectionOrCancel,
    collected,
    putAwayTime,
    goodsBaseInfo: {
      goodsName,
      goodsNo,
      ecommerceControlPrice,
      sellerOuterNo,
    },
    mallShopSimpleVo,
    mallShopSimpleVo: {
      vendorName,
    },
    colorList,
    mainPics,
    picUrl,
    businessProps,
    goodsContentUrl,
    goodsPicGroup,
    onChangePicGroup,
    currentPicGroup,
    setPicAndSize,
    currentSizeList,
    onSkuValueChange,
    colorCode,
    totalNum,
    totalPrice,
    addToCart,
    buyNow,
    countDownTime,
    promotionPreviewVo,
    isActive,
    openLaunchModal,
    afterSale,
    videoUrl,
    doesAddStyle,
    addMyStyle,
    setPicUrl,
    lunchLoading,
    addStyleLoading,
    downloadLoading,
    addCartLoading,
    gotoMyStyle,
    rightSiderStore,
    stallNewStyleList,
    setStallNewStyleListCarousel,
    mainPre,
    mainNext,
    similarStyleList,
    virtualSaleNum,
    virtualSelectNum,
    status,
    isSingleProps,
    propsName,
    maxWholePrice,
    maxDiscountPrice,
    maxActivityPrice,
    liveGoodsVo,
    propsCode,
  } = store;
  const {
    checkLoginFn,
    visible,
    setVisible,
  } = useCheckLogin();
  console.log('currentSizeList..', currentSizeList, colorList);
  return (
    <>
      <Navigation/>
      <div
        className={styles.page}
        id="page"
      >
        <div className={styles.pageContainer}>
          <div className={styles.goodsPane}>
            <div className={styles.mainPictureContainer}>
              <GoodsMainPics
                mainPics={mainPics}
                picUrl={picUrl}
                setUrl={setPicUrl}
                status={status}
                videoUrl={videoUrl}
              />
            </div>
            <div className={styles.operationPane}>
              <GoodsInfo
                activityPrice={activityPrice}
                countDownTime={countDownTime}
                discountPrice={discountPrice}
                ecommerceControlPrice={ecommerceControlPrice}
                goodsNo={goodsNo}
                isActive={isActive}
                isShowText
                liveGoodsVo={liveGoodsVo}
                maxActivityPrice={maxActivityPrice}
                maxDiscountPrice={maxDiscountPrice}
                maxWholePrice={maxWholePrice}
                price={minWholePrice}
                promotionPreviewVo={promotionPreviewVo}
                sellerOuterNo={sellerOuterNo}
                time={putAwayTime}
                title={goodsName}
                virtualSaleNum={virtualSaleNum}
                virtualSelectNum={virtualSelectNum}
              />
              <PicSeries
                currentPicGroupId={currentPicGroup}
                goodsPicGroup={goodsPicGroup}
                onChangePicGroup={onChangePicGroup}
              />
              <AfterSale {...afterSale}/>
              {!isSingleProps && (
                <GoodsColor
                  colorCode={colorCode}
                  colorList={colorList}
                  picUrl={picUrl}
                  setPicUrl={setPicAndSize}
                />
              )}
              <GoodsSize
                onSkuValueChange={onSkuValueChange}
                propsName={propsName}
                sizeList={currentSizeList}
                totalNum={totalNum}
                totalPrice={totalPrice}
              />
              <OperationArea
                collected={collected}
                collectionOrCancel={() => checkLoginFn(collectionOrCancel)}
                downloadQRcode={downloadQRcode}
                goodsId={goodsId}
                goodsName={goodsName}
                picUrl={picUrl}
                price={minWholePrice}
                vendorName={vendorName}
              />
              <OperationBtns
                addCartLoading={addCartLoading}
                addStyleLoading={addStyleLoading}
                doesAddStyle={doesAddStyle}
                downloadLoading={downloadLoading}
                gotoMyStyle={gotoMyStyle}
                lunchLoading={lunchLoading}
                onClickAddStyle={() => {
                  checkLoginFn(addMyStyle);
                }}
                onClickAddToCart={() => checkLoginFn(addToCart)}
                onClickBuyNow={() => checkLoginFn(buyNow)}
                onClickDownLoad={() => checkLoginFn(openDownload)}
                onClickLaunch={() => checkLoginFn(openLaunchModal)}
                status={status}
              />
            </div>
          </div>
          <div className={styles.goodsNewWrap}>
            <StallNewStyle
              mainNext={mainNext}
              mainPre={mainPre}
              rightSiderStore={rightSiderStore}
              setStallNewStyleListCarousel={setStallNewStyleListCarousel}
              stallNewStyleList={stallNewStyleList}
            />
            {status === 3 ? (
              <Sticky
                innerZ={1}
                top={200}
              >
                <FastTrack
                  doesAddStyle={doesAddStyle}
                  gotoMyStyle={gotoMyStyle}
                  onClickAddStyle={() => {
                    checkLoginFn(addMyStyle);
                  }}
                  onClickDownLoad={() => checkLoginFn(openDownload)}
                  onClickLaunch={() => checkLoginFn(openLaunchModal)}
                />
              </Sticky>
            ) : null}
          </div>
          <div className={styles.goodsParams}>
            <div>
              <VendorInfo mallShopSimpleVo={mallShopSimpleVo}/>
              <PerformanceProcess/>
              <SimilarStyle
                rightSiderStore={rightSiderStore}
                similarStyleList={similarStyleList}
              />

            </div>
            <GoodsAttributes
              businessProps={businessProps}
              htmlUrl={goodsContentUrl}
            />
          </div>
          <FooterLogo/>
          <Footer/>
        </div>
      </div>
      <LaunchModal store={launchStore}/>
      <DownloadModal store={downloadStore}/>
      <RightSider store={rightSiderStore}/>
      <LoginModal
        setVisible={setVisible}
        visible={visible}
      />
      <div
        className={styles.renderModal}
        id="modal"
      />
    </>
  );
});

// 款式信息
const GoodsInfo: React.FC<Partial<{
  title: string; // 标题
  goodsNo: string; // 货号
  time: string; // 上架时间
  discountPrice: string; // 折扣价
  isShowText: boolean; // 展示价格文字
  price: number; // 进货价
  activityPrice: number;
  isActive: string; // 活动状态
  promotionPreviewVo;
  countDownTime;
  goodsSimpleVos;
  ecommerceControlPrice: number;
  virtualSaleNum: number;
  virtualSelectNum: number;
  sellerOuterNo: string;// 货号
  maxDiscountPrice: number;
  maxWholePrice: number;
  maxActivityPrice: number;
  liveGoodsVo;
}>> = observer((props) => {
  const {
    title = '',
    discountPrice,
    isShowText = true,
    activityPrice,
    price,
    goodsNo = '',
    time = '',
    isActive,
    promotionPreviewVo,
    countDownTime,
    goodsSimpleVos,
    ecommerceControlPrice,
    virtualSaleNum,
    virtualSelectNum,
    sellerOuterNo = '',
    maxDiscountPrice,
    maxWholePrice,
    maxActivityPrice,
    liveGoodsVo,
  } = props;
  const bgColors = promotionPreviewVo?.bgColor?.split(',') || [];
  const liveGoodsPrice = liveGoodsVo?.goodsLivePrice?.toString()
    .split('.') || [];
  const sellingPoint = SellingPointTag[liveGoodsVo?.sellingPointLabelCode];
  return (
    <div className={styles.goodsInfoContent}>
      <div className={styles.goodsTitle}>
        {title}
      </div>
      <span className={`${styles.tag} ${styles.mt12}`}>
        {(liveGoodsVo?.sellingPointLabelCode > 0 && sellingPoint) && (
          <Tag
            backgroundColor={sellingPoint?.backgroundColor}
            color={sellingPoint?.color}
            title={liveGoodsVo?.sellingPointLabelName || sellingPoint?.text}
          />
        )}
        {liveGoodsVo?.fastReflexes > 0 && (
          <Tag
            backgroundColor="#FB071F14"
            color="#FB071F"
            title={`${liveGoodsVo.fastReflexes}天快返`}
          />
        )}
      </span>
      <div className={styles.brandAndTime}>
        <div>
          {`货号：${sellerOuterNo || ''}`}
        </div>
        <div className={styles.brand}>
          {`上新时间：${time || ''}`}
        </div>
      </div>
      {
        isActive !== 'init' && (
          <div
            className={`${styles.activeCountDownWrap} animate__animated animate__fadeIn`}
            style={{ background: 'linear-gradient(270deg, #FFE3E3 0%, #FB071F 100%)' }}
          >
            <img
              alt=""
              className={styles.styleDetailBg}
              src={styleDetailBg}
            />
            <img
              alt=""
              className={styles.countdownImg}
              src={styleDetailClock}
            />
            <span style={{ color: '#fff' }}>
              【
              {promotionPreviewVo?.name || ''}
              】
            </span>
            <CountDownCom
              countDownTime={countDownTime || {}}
              pcFontColor={bgColors}
            />
            <div
              className={styles.gotoActivePage}
              onClick={() => {
                window.open(`/egenie-ts-vogue/searchResult/index?activeId=${promotionPreviewVo.id}&pageOrigin=active`);
              }}
            >
              查看活动
              <RightOutlined
                style={{
                  fontSize: 9,
                  color: '#fff',
                }}
              />
            </div>
          </div>
        )
      }
      <div className={styles.priceWrapper}>
        <div className={styles.priceContainer}>
          {
            (goodsSimpleVos || isActive !== 'end' || !activityPrice) ? (
              <Price
                activityPrice={activityPrice}
                discountPrice={discountPrice}
                isShowText
                maxActivityPrice={maxActivityPrice}
                maxDiscountPrice={maxDiscountPrice}
                maxWholePrice={maxWholePrice}
                price={price}
              />
            ) : (
              <ActivePriceComponent
                activityPrice={activityPrice}
                discountPrice={discountPrice}
                isActive={isActive}
                maxActivityPrice={maxActivityPrice}
                maxDiscountPrice={maxDiscountPrice}
                maxWholePrice={maxWholePrice}
                price={price}
              />
            )
          }
          {
            !goodsSimpleVos && Boolean(ecommerceControlPrice) && (
              <div className={styles.ecommerceControlPriceWrap}>
                电商控价：
                <span className={styles.ecommerceControlPrice}>
                  {ecommerceControlPrice}
                </span>
                <Popover
                  content="销售时请不要低于此价格，否则会被供应商投诉"
                >
                  <InfoCircleFilled/>
                </Popover>
              </div>
            )
          }
          {
            liveGoodsVo && liveGoodsVo?.goodsLivePrice > 0 && (
              <div className={styles.liveGoodsPriceWrap}>
                <span>
                  建议直播价：
                </span>
                <span className={`${styles.font14} ${styles.lh16} ${styles.font500} ${styles.clRed}`}>
                  ¥
                </span>
                <span className={`${styles.font24} ${styles.font500} ${styles.clRed}`}>
                  {liveGoodsPrice.length > 0 ? liveGoodsPrice[0] : 0}
                </span>
                <span className={`${styles.font16} ${styles.lh16} ${styles.font500} ${styles.clRed}`}>
                  .
                  {liveGoodsPrice.length > 1 ? liveGoodsPrice[1] : '00'}
                </span>
              </div>
            )
          }
          {
            liveGoodsVo && liveGoodsVo?.brokerageRate > 0 && (
              <div className={styles.liveGoodsPriceWrap}>
                <span>
                  佣金：
                </span>
                <span className={`${styles.font24} ${styles.font500} ${styles.clRed}`}>
                  {liveGoodsVo.brokerageRate}
                </span>
                <span className={`${styles.font16} ${styles.lh16} ${styles.font500} ${styles.clRed}`}>
                  %
                </span>
              </div>
            )
          }
        </div>
        {liveGoodsVo && <Wechat moduleType="mall_live_goods_detail"/>}
      </div>
      <div className={styles.virtualSaleWrap}>
        <div>
          销量：
          {virtualSaleNum > 100000 ? '99999+' : virtualSaleNum}
          件
        </div>
        <div>
          ｜
        </div>
        <div>
          铺货次数：
          {virtualSelectNum || 0}
          次
        </div>
      </div>
    </div>
  );
});

export default GoodsDetail;
