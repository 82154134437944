import { Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { LoginContent } from '../pages/login';
import styles from '../pages/login/index.less';
import { Store } from '../pages/login/store';
import { checkLoginStatus } from './tools';

const store = new Store();

/**
 * 校验登录状态的hook，没登录展示弹窗
 * @returns {checkLoginFn, visible, setVisible} checkLoginFn:校验的函数，需要传成功的回调; visible:登录弹窗显隐；setVisible:手动设置显隐藏
 */
export const useCheckLogin = () => {
  const [
    visible,
    setVisible,
  ] = useState(false);

  const checkLoginFn = useCallback((successCB?: () => void) => {
    checkLoginStatus()
      .then((result: boolean) => {
        if (!result) {
          setVisible(true);
        } else {
          setVisible(false);
          successCB && successCB();
        }
      });
  }, []);

  return {
    checkLoginFn,
    visible,
    setVisible,
  };
};

// 登录弹窗
export const LoginModal: React.FC<{
  visible: boolean; setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}> = observer((props) => {
  const {
    visible,
    setVisible,
  } = props;
  return (
    <Modal
      className={styles.loginModal}
      destroyOnClose
      footer={null}
      onCancel={() => {
        setVisible(false);
      }}
      open={visible}
      width={431}
    >
      <LoginContent
        modalLogin
        store={store}
      />
    </Modal>
  );
});
