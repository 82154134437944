import { SwapRightOutlined } from '@ant-design/icons';
import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../../utils';
import styles from './index.less';

export const Process: React.FC<unknown> = (props) => {
  return (
    <div className={styles.process}>
      <div className={styles.processLine}>
        <div>
          <img src={`${PUBLIC_IMAGE_URL}putShelf.png`}/>
          <span>
            铺货电商平台
          </span>
        </div>
        <img
          className={styles.rightLineArrow}
          src={`${PUBLIC_IMAGE_URL}rightLineArrow.png`}
        />
        <div>
          <img src={`${PUBLIC_IMAGE_URL}dropShip.png`}/>
          <span>
            一件代发
          </span>
        </div>
        <img
          className={styles.rightLineArrow}
          src={`${PUBLIC_IMAGE_URL}rightLineArrow.png`}
        />
        <div>
          <img src={`${PUBLIC_IMAGE_URL}delivery.png`}/>
          <span>
            网仓发货
          </span>
        </div>
        <img
          className={styles.rightLineArrow}
          src={`${PUBLIC_IMAGE_URL}rightLineArrow.png`}
        />
        <div>
          <img src={`${PUBLIC_IMAGE_URL}afterSale.png`}/>
          <span>
            网仓售后
          </span>
        </div>
      </div>
    </div>
  );
};
