import { toFixed } from 'egenie-common';
import { observer } from 'mobx-react';
import type { ReactElement } from 'react';
import React from 'react';
import { getPrice } from '../../../utils';
import styles from './index.less';

interface IPriceProps {
  discountPrice: string;
  price: number;
  activityPrice?: number;
  isActive?: string;
  maxDiscountPrice?: number;
  maxWholePrice?: number;
  maxActivityPrice?: number;
}

// 活动价
export const ActivePriceComponent = observer(({
  discountPrice,
  price,
  isActive,
  activityPrice,
  maxDiscountPrice,
  maxWholePrice,
  maxActivityPrice,
}: IPriceProps): ReactElement => {
  return (
    <div className={styles.activePriceComponentWrap}>
      {
        isActive === 'end' ? (
          <>
            <ActivePrice
              activityPrice={activityPrice}
              discountPrice={discountPrice}
              isActive={isActive}
              maxActivityPrice={maxActivityPrice}
              maxDiscountPrice={maxDiscountPrice}
              maxWholePrice={maxWholePrice}
              price={price}
            />
            <OriginPrice
              discountPrice={discountPrice}
              isActive={isActive}
              maxActivityPrice={maxActivityPrice}
              maxDiscountPrice={maxDiscountPrice}
              maxWholePrice={maxWholePrice}
              price={price}
            />
          </>
        ) : isActive === 'start' ? (
          <>
            <OriginPrice
              discountPrice={discountPrice}
              isActive={isActive}
              maxActivityPrice={maxActivityPrice}
              maxDiscountPrice={maxDiscountPrice}
              maxWholePrice={maxWholePrice}
              price={price}
            />
            <ActivePrice
              activityPrice={activityPrice}
              discountPrice={discountPrice}
              isActive={isActive}
              maxActivityPrice={maxActivityPrice}
              maxDiscountPrice={maxDiscountPrice}
              maxWholePrice={maxWholePrice}
              price={price}
            />
          </>
        ) : <div/>
      }
    </div>
  );
});

export const ActivePrice = observer(({
  activityPrice,
  isActive,
  discountPrice,
  price,
  maxActivityPrice,
  maxDiscountPrice,
  maxWholePrice,
}: Partial<IPriceProps>): ReactElement => {
  const _price = getPrice(price, discountPrice, activityPrice);
  const _maxPrice = getPrice(maxWholePrice, maxDiscountPrice, maxActivityPrice);
  return (
    <div className={`${styles.activePriceWrap} ${isActive === 'start' ? styles.ml4 : ''}`}>
      <span className={styles.activeMark}>
        活动
      </span>
      <span className={styles.activePrice}>
        ¥
        {_price ? _price.toFixed(2) : 0}
      </span>
      {_maxPrice > _price && (
        <span className={styles.activePriceUp}>
          起
        </span>
      )}
    </div>
  );
});

export const OriginPrice = observer(({
  discountPrice,
  price,
  isActive,
  maxActivityPrice,
  maxDiscountPrice,
  maxWholePrice,
}: Partial<IPriceProps>): ReactElement => {
  const _price = isActive === 'end' ? price : (discountPrice || price);
  const _maxPrice = isActive === 'end' ? maxWholePrice : (maxDiscountPrice || maxWholePrice);
  const max = toFixed(_price, 2)
    .toString()
    .split('.')[0];
  const min = toFixed(_price, 2)
    .toString()
    .split('.')[1];
  let sectionMax = 0;
  let sectionMin = 0;
  if (_maxPrice !== _price) {
    sectionMax = Number(toFixed(_maxPrice, 2)
      .toString()
      .split('.')[0]);
    sectionMin = Number(toFixed(_maxPrice, 2)
      .toString()
      .split('.')[1]);
  }

  return (
    <div className={`${styles.originPriceWrap} ${isActive === 'end' ? styles.ml4 : ''}`}>
      {/* {
        Boolean(discountPrice) && (
          <span className={`${styles.specialPrice} ${styles.clRed} ${styles.mr4}`}>
            专享
          </span>
        )
      } */}
      <span className={`${styles.clRed} ${styles.font500} ${styles.flex} ${styles.faifEnd} ${isActive === 'end' ? styles.lineThrough : ''}`}>

        <span className={styles.font14}>
          ¥
        </span>
        <span className={styles.font16}>
          {max}
        </span>
        <span className={styles.font12}>
          .
          {min}
        </span>
        {sectionMax !== 0 && (
          <span className={styles.font12}>
            起
          </span>
        )}
      </span>
    </div>
  );
});

